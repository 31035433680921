import React from 'react';
import Header from './header';
import Footer from './footer';
import NavBar from './nav-bar';
import CeilingFansDescription from './ceiling-fans-description';
import CeilingFansBrandsContainer from './ceiling-fans-brands-container';
import FeaturedCeilingFansContainer from './featured-ceiling-fans-container';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import 'autotrack';
import LightingBanner from './lighting-banner';


class CeilingFansProductsScreen extends React.Component {
    componentDidMount() {
        // window.onresize =  window.scrollTo(0, this.props.returningCategoryScreenYOffset);
        // const targetNode = document.getElementById('category-screen');
        // const config = { attributes: true, childList: true, subtree: true };
        // const windowScrollTo = () => {
        //     window.scrollTo(0, this.props.returningCategoryScreenYOffset)
        // }
        // const observer = new MutationObserver(windowScrollTo);
        // observer.observe(targetNode, config);
        // console.log(this.props)
        // window.scrollTo(0, this.props.returningProductsScreenYOffset);
        // this.props.dispatch({type: 'RESET_RETURNING_PRODUCTS_SCREEN_Y_OFFSET', returningProductsScreenYOffset: 0});
        // this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname})
        // window.ga('create', 'UA-132930353-1', 'auto');
        // window.ga('require', 'urlChangeTracker');
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
        // window.history.scrollRestoration = 'manual';
        if (this.props.productCategoriesArray.length < 1) {     
            console.log('fetching') 
            fetch(`${process.env.REACT_APP_API_HOST}/api/getproductcategories`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            })
            .then(results=> {
                return results.json();
            })
            .then(results=> {
                let productCategoriesResults = results;
                this.props.dispatch({type: 'SET_PRODUCT_CATEGORIES_ARRAY', productCategoriesArray: productCategoriesResults})
            })
        }
        // if (this.props.returningProductsScreenYOffset !== 0) {
        //     setTimeout (() => {    
        //         window.scrollTo(0, this.props.returningProductsScreenYOffset);
        //         this.props.dispatch({type: 'RESET_RETURNING_PRODUCTS_SCREEN_Y_OFFSET', returningProductsScreenYOffset: 0});
        //         this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname})
        //     }, 180)
        // } else {
        //     window.scrollTo(0, 0);

        // }
        setTimeout (() => {
            // if (this.props.returningProductsScreenYOffset !== 0) {
                window.scrollTo(0, this.props.returningProductsScreenYOffset);
            // }
            this.props.dispatch({type: 'RESET_RETURNING_PRODUCTS_SCREEN_Y_OFFSET', returningProductsScreenYOffset: 0});
            this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname})
        }, 180)
    }

    render() {
        return <div className="ceiling-fans-screen">
            <Helmet>
                <title>Atlanta Ceiling Fans : Ceiling Fans</title>
                <meta name="description" content="Shop Atlanta Ceiling Fans in Roswell, Georgia for the best selection of ceiling fans, parts and accessories.  Our showroom has over 150 designer ceiling fans on display many of which are in stock and ready to take home today.  We carry a large selection of indoor and outdoor designer ceiling fans in many styles (Modern, Farmhouse, Traditional, Tropical) and sizes (26” small up to 99” large fans)." />
                <meta name="twitter:title" content="Atlanta Ceiling Fans : Ceiling Fans" />
                <meta property="og:title" content="Atlanta Ceiling Fans : Ceiling Fans" />
                <meta property="og:description" content="Shop Atlanta Ceiling Fans in Roswell, Georgia for the best selection of ceiling fans, parts and accessories.  Our showroom has over 150 designer ceiling fans on display many of which are in stock and ready to take home today.  We carry a large selection of indoor and outdoor designer ceiling fans in many styles (Modern, Farmhouse, Traditional, Tropical) and sizes (26” small up to 99” large fans)." />
                <meta property="og:image" content={`${process.env.REACT_APP_API_HOST}/logos/logo_header.svg`} />
            </Helmet>
            <Header />
            <NavBar />
            <LightingBanner />
            <CeilingFansDescription />
            <FeaturedCeilingFansContainer {...this.props}/>
            <CeilingFansBrandsContainer />
            <Footer />
        </div>
    }
}

let ConnectedCeilingFansProductsScreen = connect(state=> {
    return {
        productCategoriesArray: state.productCategoriesArray,
        // lightingFixtureCategoriesArray: state.lightingFixtureCategoriesArray,
        productsScreenPreviousYOffset: state.productsScreenPreviousYOffset,
        returningProductsScreenYOffset: state.returningProductsScreenYOffset,
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl
    }
})(CeilingFansProductsScreen);

export default ConnectedCeilingFansProductsScreen;