import React from 'react';

let FanMotorTypeList = (props) =>
    <ul className="fan-motor-type-list">
            <li className="fan-motor-type-list-item">
                {props.currentCeilingFanViewed.motorType}
            </li>
    </ul>


export default FanMotorTypeList;