import React from 'react';

let LightingFixturesDescription = () =>
    <div className="lighting-fixtures-description">
        <h1 className="lighting-fixtures-description-header">
            Lighting Fixtures
        </h1>
        <p className="lighting-fixtures-description-paragraph">
            In store you will find a specially curated selection of brand name lighting fixtures that include <span className="lighting-fixtures-description-emphasis">chandeliers, pendants, vanity lighting, and outdoor lighting.</span> We offer a wide array of styles, from traditional, transitional, crystal, farmhouse, rustic, coastal, modern, contemporary, and everything in between! Most lighting fixtures are available in multiple sizes, giving you many options no matter the size of your space.
        </p>
    </div>


export default LightingFixturesDescription;