import React from 'react';

let CategoryDescription = (props) =>
    <div className="category-description">
        <h1 className="category-description-header" >{props.currentProductCategory.productCategory}</h1>
        <p className="category-description-paragraph">
            {props.currentProductCategory.categoryDescription}
        </p>
    </div>


export default CategoryDescription;