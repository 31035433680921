import React from 'react';
import Header from './header';
import NavBar from './nav-bar';
import { connect } from 'react-redux';
import Footer from './footer';
import LightingFixtureDetailsContainer from './lighting-fixture-details-container';
import PageNotFoundScreen from './page-not-found-screen';
import Helmet from 'react-helmet';
import LightingFixtureLoadingScreen from './lighting-fixture-loading-screen';
import 'autotrack';


class LightingFixtureDetailsScreen extends React.Component {
    componentDidMount() {
        this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname});
        window.scrollTo(0, 0);
        // console.log(this.props)
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
        if (this.props.currentLightingFixtureViewed.name === '' || this.props.currentLightingFixtureViewed.name !== this.props.match.params.id) {
            fetch(`${process.env.REACT_APP_API_HOST}/api/lightingfixturedetails/${this.props.match.params.category}/${this.props.match.params.id}`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            })
                .then(results=> {
                    return results.json();
                })
                .then(results => {
                    if (results === 'No Results') {
                        this.props.dispatch({type: 'SET_CURRENT_LIGHTING_FIXTURE_FOUND_STATUS', currentLightingFixtureFoundStatus: false})
                    } else {
                        let lightingFixtureResults = results.lightingFixtureResults;
                        let lightingFixtureCategoryDescription = results.lightingFixtureCategoryDescription[0];
                        this.props.dispatch({type: 'SET_CURRENT_LIGHTING_FIXTURE_FOUND_STATUS', currentLightingFixtureFoundStatus: true});
                        // this.props.dispatch({type: 'SET_CEILING_FANS_ARRAY', ceilingFansArray: ceilingFanResults})
                        this.props.dispatch({type: 'SET_CURRENT_LIGHTING_FIXTURE_CATEGORY', currentLightingFixtureCategory: lightingFixtureCategoryDescription});
                        this.props.dispatch({type: 'SET_CURRENT_LIGHTING_FIXTURE_VIEWED', currentLightingFixtureViewed: lightingFixtureResults});
                        this.props.dispatch({type: 'SET_LIGHTING_FIXTURE_CATEGORY_FOUND_STATUS', lightingFixtureCategoryFoundStatus: true});
                        fetch(`${process.env.REACT_APP_API_HOST}/api/lightingfixtures/${this.props.match.params.category}`, {
                            method: 'GET',
                            headers: {'Content-Type': 'application/json'}
                        })
                            .then(results=> {
                                return results.json();
                            })
                            .then(results => {
                                let lightingFixtureArrayByCategoryResults = results.lightingFixtureResults;
                                this.props.dispatch({type: 'SET_LIGHTING_FIXTURES_ARRAY', lightingFixturesArray: lightingFixtureArrayByCategoryResults})
                            })
                    }
                })
        } 
    }
    render() {
        return <div className="fan-details-screen">
            {(this.props.match.params.id === this.props.currentLightingFixtureViewed.productUrl)?
                <div>
                    <Helmet>
                        <title>
                            Atlanta Ceiling Fans : {this.props.currentLightingFixtureViewed.title}
                        </title>
                        <meta name="description" content="Visit our blog page which offers helpful ceiling fan tips and information to make your buying process a breeze." />
                        <meta name="twitter:title" content="Atlanta Ceiling Fans" />
                        <meta property="og:title" content="Atlanta Ceiling Fans" />
                        <meta property="og:description" content="Visit our blog page which offers helpful ceiling fan tips and information to make your buying process a breeze." />
                        <meta property="og:image" content={`${process.env.REACT_APP_API_HOST}/logos/logo_header.svg`} />
                    </Helmet>
                    <Header />
                    <NavBar />
                    <LightingFixtureDetailsContainer {...this.props} />
                    <Footer />
                </div>
            :
            (this.props.currentLightingFixtureFoundStatus === false)?
                <PageNotFoundScreen {...this.props}/>
            :
                <LightingFixtureLoadingScreen {...this.props}/>
            }
        </div>
    }
}

let ConnectedLightingFixtureDetailsScreen = connect(state=> {
    return {
        lightingFixtureCategoriesArray: state.lightingFixtureCategoriesArray,
        lightingFixturesArray: state.lightingFixturesArray,
        currentLightingFixtureCategory: state.currentLightingFixtureCategory,
        currentLightingFixtureFoundStatus: state.currentLightingFixtureFoundStatus,
        currentLightingFixtureViewed: state.currentLightingFixtureViewed,
        lightingFixtureCategoryFoundStatus: state.lightingFixtureCategoryFoundStatus,
        categoryScreenPreviousYOffset: state.categoryScreenPreviousYOffset,
        returningCategoryScreenYOffset: state.returningCategoryScreenYOffset,
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl
    }
})(LightingFixtureDetailsScreen);

export default ConnectedLightingFixtureDetailsScreen;