import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './header';
import NavBar from './nav-bar';
import Footer from './footer';
import { connect } from 'react-redux';
import 'autotrack';


class ContactFormErrorScreen extends React.Component {
    componentDidMount() {
        this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname});
        // window.ga('create', 'UA-132930353-1', 'auto');
        // window.ga('require', 'urlChangeTracker');
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
    }
    render() {
        return <div>
            <Header />
            <NavBar />
            <div className="message-sent-error-container">
                <p className="message-sent-error-paragraph">
                    We apologize, there was a problem sending your message. Please try sending it again.
                </p>
                <NavLink 
                    className='message-sent-error-contact-screen-return-navlink' 
                    to='/contact' 
                    exact={true}
                >
                    Back to Contact Form
                </NavLink>
            </div>
            <Footer />
        </div>
    }
};


let ConnectedContactFormErrorScreen = connect(state=> {
    return {
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl
    }
})(ContactFormErrorScreen);

export default ConnectedContactFormErrorScreen;