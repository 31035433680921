import React from 'react';

let FanControlTypeListItem = (props) =>

    <React.Fragment>
        {(props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === true && props.currentCeilingFanViewed.smartFan === true && props.currentCeilingFanViewed.wifiCompatible === true)?
            <React.Fragment>
                <li className="fan-control-type-list-item">
                    Handheld Remote Control and In-Wall Remote Control Included
                </li>
                <li className="fan-control-type-list-item">
                    WiFi Compatible, can use an app to control the fan
                </li>
            </React.Fragment>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === true && props.currentCeilingFanViewed.inWallRemoteControlAvailable === true && props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
            <React.Fragment>
            <li className="fan-control-type-list-item">
                Handheld Remote Control Included
            </li>
            <li className="fan-control-type-list-item">
                In-Wall Remote Control Available (Sold Separately)
            </li>
            <li className="fan-control-type-list-item">
                Hardwired Wall Control Available (Sold Separately)
            </li>
            </React.Fragment>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === true && props.currentCeilingFanViewed.inWallRemoteControlAvailable === true && props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
            <React.Fragment>
                <li className="fan-control-type-list-item">
                    Handheld Remote Control Included
                </li>
                <li className="fan-control-type-list-item">
                    In-Wall Remote Control Available (Sold Separately)
                </li>
            </React.Fragment>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === true && props.currentCeilingFanViewed.inWallRemoteControlAvailable === false)?
            <li className="fan-control-type-list-item">
                Handheld Remote Control Included
            </li>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === true && props.currentCeilingFanViewed.handheldRemoteControlAvailable === true && props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
            <React.Fragment>
                <li className="fan-control-type-list-item">
                    In-Wall Remote Control Included
                </li>
                <li className="fan-control-type-list-item">
                    Handheld Remote Control Available (Sold Separately)
                </li>
                <li className="fan-control-type-list-item">
                    Hardwired Wall Control Available (Sold Separately)
                </li>
            </React.Fragment>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === true && props.currentCeilingFanViewed.handheldRemoteControlAvailable === true && props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
            <React.Fragment>
                <li className="fan-control-type-list-item">
                    In-Wall Remote Control Included
                </li>
                <li className="fan-control-type-list-item">
                    Handheld Remote Control Available (Sold Separately)
                </li>
            </React.Fragment>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === true && props.currentCeilingFanViewed.handheldRemoteControlAvailable === false)?
            <li className="fan-control-type-list-item">
                In-Wall Remote Control Included
            </li>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === false && props.currentCeilingFanViewed.hardwiredWallControlIncluded === true && props.currentCeilingFanViewed.handheldRemoteControlAvailable === true && props.currentCeilingFanViewed.inWallRemoteControlAvailable === true)?
            <React.Fragment>
                <li className="fan-control-type-list-item">
                    Hardwired Wall Control Included
                </li>
                <li className="fan-control-type-list-item">
                    Handheld Remote Control Available (Sold Separately)
                </li>
                <li className="fan-control-type-list-item">
                    In-Wall Remote Control Available (Sold Separately)
                </li>
            </React.Fragment>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === false && props.currentCeilingFanViewed.hardwiredWallControlIncluded === true && props.currentCeilingFanViewed.handheldRemoteControlAvailable === true && props.currentCeilingFanViewed.inWallRemoteControlAvailable === false)?
            <React.Fragment>
                <li className="fan-control-type-list-item">
                    Hardwired Wall Control Included
                </li>
                <li className="fan-control-type-list-item">
                    Handheld Remote Control Available (Sold Separately)
                </li>
            </React.Fragment>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === false && props.currentCeilingFanViewed.hardwiredWallControlIncluded === true && props.currentCeilingFanViewed.handheldRemoteControlAvailable === false)?
            <li className="fan-control-type-list-item">
                Hardwired Wall Control Included
            </li>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === false && props.currentCeilingFanViewed.hardwiredWallControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlAvailable === true && props.currentCeilingFanViewed.inWallRemoteControlAvailable === true && props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
            <React.Fragment>
                <li className="fan-control-type-list-item">
                    Handheld Remote Control Available (Sold Separately)
                </li>
                <li className="fan-control-type-list-item">
                    In-Wall Remote Control Available (Sold Separately)
                </li>
                <li className="fan-control-type-list-item">
                    Hardwired Wall Control Available (Sold Separately)
                </li>
            </React.Fragment>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === false && props.currentCeilingFanViewed.hardwiredWallControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlAvailable === true && props.currentCeilingFanViewed.inWallRemoteControlAvailable === true && props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
            <React.Fragment>
                <li className="fan-control-type-list-item">
                    Handheld Remote Control Available (Sold Separately)
                </li>
                <li className="fan-control-type-list-item">
                    In-Wall Remote Control Available (Sold Separately)
                </li>
            </React.Fragment>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === false && props.currentCeilingFanViewed.hardwiredWallControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlAvailable === true && props.currentCeilingFanViewed.inWallRemoteControlAvailable === false && props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
            <React.Fragment>
                <li className="fan-control-type-list-item">
                    Handheld Remote Control Available (Sold Separately)
                </li>
                <li className="fan-control-type-list-item">
                    Hardwired Wall Control Available (Sold Separately)
                </li>
            </React.Fragment>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === false && props.currentCeilingFanViewed.hardwiredWallControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlAvailable === true && props.currentCeilingFanViewed.inWallRemoteControlAvailable === false && props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
            <li className="fan-control-type-list-item">
                Handheld Remote Control Available (Sold Separately)
            </li>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === false && props.currentCeilingFanViewed.hardwiredWallControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlAvailable === false && props.currentCeilingFanViewed.inWallRemoteControlAvailable === true && props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
            <React.Fragment>
                <li className="fan-control-type-list-item">
                    In-Wall Remote Control Available (Sold Separately)
                </li>
                <li className="fan-control-type-list-item">
                    Hardwired Wall Control Available (Sold Separately)
                </li>
            </React.Fragment>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === false && props.currentCeilingFanViewed.hardwiredWallControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlAvailable === false && props.currentCeilingFanViewed.inWallRemoteControlAvailable === true && props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
            <li className="fan-control-type-list-item">
                In-Wall Remote Control Available (Sold Separately)
            </li>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === false && props.currentCeilingFanViewed.hardwiredWallControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlAvailable === false && props.currentCeilingFanViewed.inWallRemoteControlAvailable === false && props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
                <li className="fan-control-type-list-item">
                    Hardwired Wall Control Available (Sold Separately)
                </li>
        :
        (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === false && props.currentCeilingFanViewed.hardwiredWallControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlAvailable === false && props.currentCeilingFanViewed.inWallRemoteControlAvailable === false && props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
            <li className="fan-control-type-list-item">
                Operated by Pull Chain Only
            </li>
        :
            <li className="fan-control-type-list-item">
                Call or Email for details
            </li>
        }
    </React.Fragment>

    // <React.Fragment>
    //     {(props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === true)?
    //         <React.Fragment>
    //             <li>
    //                 Handheld Remote Control and In-Wall Remote Control Included
    //             </li>
    //         </React.Fragment>
    //     :
    //     (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === true && props.currentCeilingFanViewed.inWallRemoteControlAvailable === true && props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
    //                     <React.Fragment>
    //                     <li>
    //                         Handheld Remote Control Included
    //                     </li>
    //                     <li>
    //                         In-Wall Remote Control Available (Sold Separately)
    //                     </li>
    //                     <li>
    //                         Hardwired Wall Control Available (Sold Separately)
    //                     </li>
    //                     </React.Fragment>
    //     :
    //     (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === true && props.currentCeilingFanViewed.inWallRemoteControlAvailable === true && props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
    //                     <React.Fragment>
    //                         <li>
    //                             Handheld Remote Control Included
    //                         </li>
    //                         <li>
    //                             In-Wall Remote Control Available (Sold Separately)
    //                         </li>
    //                     </React.Fragment>
    //             :
    //             (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlIncluded === true && props.currentCeilingFanViewed.inWallRemoteControlAvailable === false)?
    //                 <li>
    //                     Handheld Remote Control Included
    //                 </li>
    //         :
    //         (props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === true && props.currentCeilingFanViewed.handheldRemoteControlAvailable === true && props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
    //             (props.currentCeilingFanViewed.inWallRemoteControlIncluded === true)?
    //                 (props.currentCeilingFanViewed.handheldRemoteControlAvailable === true)?
    //                         <React.Fragment>
    //                             <li>
    //                                 In-Wall Remote Control Included
    //                             </li>
    //                             <li>
    //                                 Handheld Remote Control Available (Sold Separately)
    //                             </li>
    //                             <li>
    //                                 Hardwired Wall Control Available (Sold Separately)
    //                             </li>
    //                         </React.Fragment>
    //                     :
    //                     (props.currentCeilingFanViewed.handheldRemoteControlIncluded === false && props.currentCeilingFanViewed.inWallRemoteControlIncluded === true && props.currentCeilingFanViewed.handheldRemoteControlAvailable === true && props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
    //                         <React.Fragment>
    //                             <li>
    //                                 In-Wall Remote Control Included
    //                             </li>
    //                             <li>
    //                                 Handheld Remote Control Available (Sold Separately)
    //                             </li>
    //                         </React.Fragment>
    //                 :
    //                 (props.currentCeilingFanViewed.inWallRemoteControlIncluded === false && props.currentCeilingFanViewed.handheldRemoteControlAvailable === false)?
    //                     <li>
    //                         In-Wall Remote Control Included
    //                     </li>
    //             :
    //             (props.currentCeilingFanViewed.inWallRemoteControlIncluded === false)?
    //                 (props.currentCeilingFanViewed.hardwiredWallControlIncluded === true)?
    //                     (props.currentCeilingFanViewed.handheldRemoteControlAvailable === true)?
    //                         (props.currentCeilingFanViewed.inWallRemoteControlAvailable === true)?
    //                             <React.Fragment>
    //                                 <li>
    //                                     Hardwired Wall Control Included
    //                                 </li>
    //                                 <li>
    //                                     Handheld Remote Control Available (Sold Separately)
    //                                 </li>
    //                                 <li>
    //                                     In-Wall Remote Control Available (Sold Separately)
    //                                 </li>
    //                             </React.Fragment>
    //                         :    
    //                         (props.currentCeilingFanViewed.inWallRemoteControlAvailable === false)?
    //                             <React.Fragment>
    //                                 <li>
    //                                     Hardwired Wall Control Included
    //                                 </li>
    //                                 <li>
    //                                     Handheld Remote Control Available (Sold Separately)
    //                                 </li>
    //                             </React.Fragment>
    //                     :
    //                     (props.currentCeilingFanViewed.handheldRemoteControlAvailable === false)?
    //                         <li>
    //                             Hardwired Wall Control Included
    //                         </li>
    //                 :
    //                 (props.currentCeilingFanViewed.hardwiredWallControlIncluded === false)?
    //                     (props.currentCeilingFanViewed.handheldRemoteControlAvailable === true)?
    //                         (props.currentCeilingFanViewed.inWallRemoteControlAvailable === true)?
    //                             (props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
    //                                 <React.Fragment>
    //                                     <li>
    //                                         Handheld Remote Control Available (Sold Separately)
    //                                     </li>
    //                                     <li>
    //                                         In-Wall Remote Control Available (Sold Separately)
    //                                     </li>
    //                                     <li>
    //                                         Hardwired Wall Control Available (Sold Separately)
    //                                     </li>
    //                                 </React.Fragment>
    //                             :
    //                             (props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
    //                                 <React.Fragment>
    //                                     <li>
    //                                         Handheld Remote Control Available (Sold Separately)
    //                                     </li>
    //                                     <li>
    //                                         In-Wall Remote Control Available (Sold Separately)
    //                                     </li>
    //                                 </React.Fragment>
    //                         :
    //                         (props.currentCeilingFanViewed.inWallRemoteControlAvailable === false)?
    //                             (props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
    //                                 <React.Fragment>
    //                                     <li>
    //                                         Handheld Remote Control Available (Sold Separately)
    //                                     </li>
    //                                     <li>
    //                                         Hardwired Wall Control Available (Sold Separately)
    //                                     </li>
    //                                 </React.Fragment>
    //                             :                                    
    //                             (props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
    //                                 <li>
    //                                     Handheld Remote Control Available (Sold Separately)
    //                                 </li>
    //                     :
    //                     (props.currentCeilingFanViewed.handheldRemoteControlAvailable === false)?
    //                         (props.currentCeilingFanViewed.inWallRemoteControlAvailable === true)?
    //                             (props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
    //                                 <React.Fragment>
    //                                     <li>
    //                                         In-Wall Remote Control Available (Sold Separately)
    //                                     </li>
    //                                     <li>
    //                                         Hardwired Wall Control Available (Sold Separately)
    //                                     </li>
    //                                 </React.Fragment>
    //                             :
    //                             (props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
    //                                 <li>
    //                                     In-Wall Remote Control Available (Sold Separately)
    //                                 </li>
    //                         :
    //                         (props.currentCeilingFanViewed.inWallRemoteControlAvailable === false)?
    //                             (props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
    //                                 <li>
    //                                     Hardwired Wall Control Available (Sold Separately)
    //                                 </li>
    //                             :
    //                             (props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
    //                             <li>
    //                                 Operated by Pull Chain Only
    //                             </li>
    //     :
    //         <li>
    //             Call or Email for details
    //         </li>
    //     }
    // </React.Fragment>
    // <React.Fragment>
    //     {(props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === true)?
    //         <React.Fragment>
    //             <li>
    //                 Handheld Remote Control and In-Wall Remote Control Included
    //             </li>
    //         </React.Fragment>
    //     :
    //     (props.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded === false)?
    //         (props.currentCeilingFanViewed.handheldRemoteControlIncluded === true)?
    //             (props.currentCeilingFanViewed.inWallRemoteControlAvailable === true)?
    //                 (props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
    //                     <React.Fragment>
    //                     <li>
    //                         Handheld Remote Control Included
    //                     </li>
    //                     <li>
    //                         In-Wall Remote Control Available (Sold Separately)
    //                     </li>
    //                     <li>
    //                         Hardwired Wall Control Available (Sold Separately)
    //                     </li>
    //                     </React.Fragment>
    //                 :
    //                 (props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
    //                     <React.Fragment>
    //                         <li>
    //                             Handheld Remote Control Included
    //                         </li>
    //                         <li>
    //                             In-Wall Remote Control Available (Sold Separately)
    //                         </li>
    //                     </React.Fragment>
    //             :
    //             (props.currentCeilingFanViewed.inWallRemoteControlAvailable === false)?
    //                 <li>
    //                     Handheld Remote Control Included
    //                 </li>
    //         :
    //         (props.currentCeilingFanViewed.handheldRemoteControlIncluded === false)?
    //             (props.currentCeilingFanViewed.inWallRemoteControlIncluded === true)?
    //                 (props.currentCeilingFanViewed.handheldRemoteControlAvailable === true)?
    //                     (props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
    //                         <React.Fragment>
    //                             <li>
    //                                 In-Wall Remote Control Included
    //                             </li>
    //                             <li>
    //                                 Handheld Remote Control Available (Sold Separately)
    //                             </li>
    //                             <li>
    //                                 Hardwired Wall Control Available (Sold Separately)
    //                             </li>
    //                         </React.Fragment>
    //                     :
    //                     (props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
    //                         <React.Fragment>
    //                             <li>
    //                                 In-Wall Remote Control Included
    //                             </li>
    //                             <li>
    //                                 Handheld Remote Control Available (Sold Separately)
    //                             </li>
    //                         </React.Fragment>
    //                 :
    //                 (props.currentCeilingFanViewed.handheldRemoteControlAvailable === false)?
    //                     <li>
    //                         In-Wall Remote Control Included
    //                     </li>
    //             :
    //             (props.currentCeilingFanViewed.inWallRemoteControlIncluded === false)?
    //                 (props.currentCeilingFanViewed.hardwiredWallControlIncluded === true)?
    //                     (props.currentCeilingFanViewed.handheldRemoteControlAvailable === true)?
    //                         (props.currentCeilingFanViewed.inWallRemoteControlAvailable === true)?
    //                             <React.Fragment>
    //                                 <li>
    //                                     Hardwired Wall Control Included
    //                                 </li>
    //                                 <li>
    //                                     Handheld Remote Control Available (Sold Separately)
    //                                 </li>
    //                                 <li>
    //                                     In-Wall Remote Control Available (Sold Separately)
    //                                 </li>
    //                             </React.Fragment>
    //                         :    
    //                         (props.currentCeilingFanViewed.inWallRemoteControlAvailable === false)?
    //                             <React.Fragment>
    //                                 <li>
    //                                     Hardwired Wall Control Included
    //                                 </li>
    //                                 <li>
    //                                     Handheld Remote Control Available (Sold Separately)
    //                                 </li>
    //                             </React.Fragment>
    //                     :
    //                     (props.currentCeilingFanViewed.handheldRemoteControlAvailable === false)?
    //                         <li>
    //                             Hardwired Wall Control Included
    //                         </li>
    //                 :
    //                 (props.currentCeilingFanViewed.hardwiredWallControlIncluded === false)?
    //                     (props.currentCeilingFanViewed.handheldRemoteControlAvailable === true)?
    //                         (props.currentCeilingFanViewed.inWallRemoteControlAvailable === true)?
    //                             (props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
    //                                 <React.Fragment>
    //                                     <li>
    //                                         Handheld Remote Control Available (Sold Separately)
    //                                     </li>
    //                                     <li>
    //                                         In-Wall Remote Control Available (Sold Separately)
    //                                     </li>
    //                                     <li>
    //                                         Hardwired Wall Control Available (Sold Separately)
    //                                     </li>
    //                                 </React.Fragment>
    //                             :
    //                             (props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
    //                                 <React.Fragment>
    //                                     <li>
    //                                         Handheld Remote Control Available (Sold Separately)
    //                                     </li>
    //                                     <li>
    //                                         In-Wall Remote Control Available (Sold Separately)
    //                                     </li>
    //                                 </React.Fragment>
    //                         :
    //                         (props.currentCeilingFanViewed.inWallRemoteControlAvailable === false)?
    //                             (props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
    //                                 <React.Fragment>
    //                                     <li>
    //                                         Handheld Remote Control Available (Sold Separately)
    //                                     </li>
    //                                     <li>
    //                                         Hardwired Wall Control Available (Sold Separately)
    //                                     </li>
    //                                 </React.Fragment>
    //                             :                                    
    //                             (props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
    //                                 <li>
    //                                     Handheld Remote Control Available (Sold Separately)
    //                                 </li>
    //                     :
    //                     (props.currentCeilingFanViewed.handheldRemoteControlAvailable === false)?
    //                         (props.currentCeilingFanViewed.inWallRemoteControlAvailable === true)?
    //                             (props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
    //                                 <React.Fragment>
    //                                     <li>
    //                                         In-Wall Remote Control Available (Sold Separately)
    //                                     </li>
    //                                     <li>
    //                                         Hardwired Wall Control Available (Sold Separately)
    //                                     </li>
    //                                 </React.Fragment>
    //                             :
    //                             (props.currentCeilingFanViewed.hardwiredWallControlAvailable === false)?
    //                                 <li>
    //                                     In-Wall Remote Control Available (Sold Separately)
    //                                 </li>
    //                         :
    //                         (props.currentCeilingFanViewed.inWallRemoteControlAvailable === false)?
    //                             (props.currentCeilingFanViewed.hardwiredWallControlAvailable === true)?
    //                                 <li>
    //                                     Hardwired Wall Control Available (Sold Separately)
    //                                 </li>
    //                             :
    //                             <li>
    //                                 Operated by Pull Chain Only
    //                             </li>
    //     :
    //         <li>
    //             Call or Email for details
    //         </li>
    //     }
    // </React.Fragment>
            

export default FanControlTypeListItem;