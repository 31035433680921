import React from 'react';

let LightingFixtureBulbInformationContainer = (props) =>
    <div className="lighting-fixture-bulb-information-container">
            <h4 className="lighting-fixture-bulb-information-container-header">Bulb Information</h4>
                <ul className="lighting-fixture-bulb-information-list">
                    <li className="lighting-fixture-bulb-information-list-item">
                        Number of Bulbs: {props.currentLightingFixtureViewed.numberOfBulbs}
                    </li>
                    <li className="lighting-fixture-bulb-information-list-item">
                        Bulb Base Type: {props.currentLightingFixtureViewed.bulbType}
                    </li>
                    <li className="lighting-fixture-bulb-information-list-item">
                        Maximum Bulb Wattage Per Bulb: {props.currentLightingFixtureViewed.bulbWattage}
                    </li>
                    <li className="lighting-fixture-bulb-information-list-item">
                        Lumens Rating: {props.currentLightingFixtureViewed.lumensRating}
                    </li>
                </ul>
    </div>


export default LightingFixtureBulbInformationContainer;