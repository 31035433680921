import React from 'react';
import ContactUsCard from './contact-us-card';
import ContactUsForm from './contact-us-form';

let ContactUsRow = (props) =>
    <div className="contact-us-row">
        <ContactUsCard />
        <ContactUsForm {...props}/>
    </div>


export default ContactUsRow;