import React from 'react';

let LightingFixtureColorsContainer = (props) =>
    <div className="lighting-fixture-colors-container">
        <h4 className="colors-header">Colors Available</h4>
        <ul className="colors-list">
            {(props.currentLightingFixtureViewed.colors.map((color, index)=>
                (props.currentLightingFixtureViewed.shown[0] === color)?
                    <li className="colors-list-item colors-list-item-shown" key={index}>
                        {`${color} (shown)`}
                    </li>
                :
                    <li className="colors-list-item" key={index}>
                        {`${color}`}
                    </li>))}
        </ul>
    </div>


export default LightingFixtureColorsContainer;