import React from 'react';
import { NavLink } from 'react-router-dom';

let BrandRow = (props) =>
    <React.Fragment>
        {(props.brand.navLink === true)?
            <li className="brand-list-item" >
                <NavLink 
                    onClick={(event) => {
                        props.dispatch({type: 'UPDATE_CATEGORY_SCREEN_PREVIOUS_Y_OFFSET', categoryScreenPreviousYOffset: (window.pageYOffset)});
                    }} 
                    className="brand-list-item-anchor" 
                    to={`/ceiling-fans/by-brand/${props.brand.brandNameUrl}`}
                >
                    {props.brand.brand}
                </NavLink>
            </li>
        :
            <li className="brand-list-item" >
                <a className="brand-list-item-anchor" href={props.brand.url} target="_blank" rel="noreferrer noopener" >{props.brand.brand}</a>
            </li>
        }
    </React.Fragment>

export default BrandRow;



// let BrandRow = (props) =>
//     <li className="brand-list-item" >
//         {console.log(props)};
//         {(props.brand.navLink === true)?
//             <NavLink 
//                 onClick={(event) => {
//                     props.dispatch({type: 'UPDATE_CATEGORY_SCREEN_PREVIOUS_Y_OFFSET', categoryScreenPreviousYOffset: (window.pageYOffset)});
//                 }} 
//                 className="brand-list-item-nav-link" 
//                 to={`/products/${props.brand.brand}`}
//             >
//                 <p className="brand-list-item-anchor">{props.brand.brand}</p>
//             </NavLink>
//         :            
//             <a className="brand-list-item-anchor" href={props.brand.url} target="_blank" rel="noreferrer noopener" >{props.brand.brand}</a>
//         };
//     </li>

// export default BrandRow;