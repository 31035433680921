import React from 'react';
import { withRouter } from 'react-router-dom';

let PageNotFoundContainer = (props) =>
    <div className="page-not-found-container">
        <h1>Page Not Found</h1>
        <p className="page-not-found-container-address">{process.env.REACT_APP_API_HOST + props.location.pathname}</p>
        <p className="page-not-found-container-paragraph">We could not find the above page on our servers</p>
        <button 
            onClick={(event)=>{
                if (!props.previousScreenUrl) {
                    props.history.push('/');
                } else {
                    props.history.push(props.previousScreenUrl)
                }
            }} 
            className="page-not-found-back-button"
        >
            {(props.previousScreenUrl)? 'Go Back' : 'Go To Home Page'}
        </button>
    </div>


export default withRouter(PageNotFoundContainer);