import React from 'react';
import NavBar from './nav-bar';
import Header from './header';
import IntroductionRow from './introduction-row';
import HomeScreenList from './home-screen-list';
import Footer from './footer';
import HomeScreenDescription from './home-screen-description';
import Helmet from 'react-helmet';
import HomeScreenImageGallery from './home-screen-image-gallery';
import { connect } from 'react-redux';
import 'autotrack';
import LightingBanner from './lighting-banner';
import BrowseProductsLinksContainer from './browse-products-links-container';


class HomeScreen extends React.Component {
    componentDidMount() {
        this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname});
        // window.ga('create', 'UA-132930353-1', 'auto');
        // window.ga('require', 'urlChangeTracker');
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
        // window.gtag('set', 'page', `${this.props.location.pathname}`)
        // window.gtag('send', 'pageview');
    }
    render() {
        return <div className="home-screen">
            <Helmet>
                <title>Atlanta Ceiling Fans : Home</title>
                <meta name="description" content="Atlanta Ceiling Fans is metro Atlanta’s premier ceiling fan specialty store.  We carry top brand-name ceiling fans, parts and accessories. With over 23 years of experience in the industry, we can help you find the perfect fan for your home." />
                <meta name="twitter:title" content="Atlanta Ceiling Fans" />
                <meta property="og:title" content="Atlanta Ceiling Fans" />
                <meta property="og:description" content="Atlanta Ceiling Fans. We offer a great selection of designer fans, modern fans, smart fans, traditional fans, outdoor fans (wet and damp), and more." />
                <meta property="og:image" content={`${process.env.REACT_APP_API_HOST}/logos/logo_header.svg`} />
            </Helmet>
            <Header />
            <NavBar />
            <LightingBanner />
            <HomeScreenImageGallery {...this.props}/>
            <IntroductionRow />
            <BrowseProductsLinksContainer />            
            <HomeScreenList {...this.props}/>
            <HomeScreenDescription />
            <Footer />
        </div>
    }
}

let ConnectedHomeScreen = connect(state=> {
    return {
        homeScreenImageGalleryCurrent: state.homeScreenImageGalleryCurrent,
        homeScreenImageGalleryArray: state.homeScreenImageGalleryArray,
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl
    }
})(HomeScreen);

export default ConnectedHomeScreen;