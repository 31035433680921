import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import LightingFixtureDetailsRow from './lighting-fixture-details-row';

let LightingFixtureDetailsContainer = (props) => 
    <div className="fan-details-container">
        <h2 className="fan-details-header">
            {`${props.currentLightingFixtureViewed.manufacturer} ${props.currentLightingFixtureViewed.title}`}
        </h2>
        <LightingFixtureDetailsRow {...props} />
        <NavLink 
            onClick={(event) => {
                props.dispatch({type: 'UPDATE_RETURNING_CATEGORY_SCREEN_Y_OFFSET', returningCategoryScreenYOffset: props.categoryScreenPreviousYOffset});
                props.dispatch({type: 'RESET_CATEGORY_SCREEN_PREVIOUS_Y_OFFSET', categoryScreenPreviousYOffset: 0});
            }}
            className="category-screen-back-to-products-screen-nav-link" 
            to={`/lighting-fixtures/${props.match.params.category}`} 
        >
            {`Back to ${(props.currentLightingFixtureCategory.lightingFixtureCategory)}`}
        </NavLink>
    </div>


let ConnectedLightingFixtureDetailsContainer = connect(state=> {
    return {
        returningCategoryScreenYOffset: state.returningCategoryScreenYOffset,
        categoryScreenPreviousYOffset: state.categoryScreenPreviousYOffset
    }
})(LightingFixtureDetailsContainer);

export default ConnectedLightingFixtureDetailsContainer;