import React from 'react';

let PartsAccessoriesDescription = () =>
    <div className="parts-accessories-description">
        <h1 className="parts-accessories-description-header">Parts & Accessories</h1>
        <p className="parts-accessories-description-paragraph">
            {`At Atlanta Ceiling Fans, we offer ceiling fan parts and accessories in store. Whether you are looking for a downrod for a new installation or parts to repair your existing fan, we can help. Please check with your fan's manufacturer to ensure accessory compatibility.`}
        </p>
    </div>


export default PartsAccessoriesDescription;