import React from 'react';
import { NavLink } from 'react-router-dom';

let BrowseProductsLinksContainer = (props) =>
    <div className="browse-products-links-container">
        <NavLink className="home-screen-products-nav-link home-screen-nav-link" to="/ceiling-fans" >Browse Ceiling Fans</NavLink>
        <NavLink className="home-screen-products-nav-link home-screen-nav-link" to="/lighting-fixtures" >Browse Lighting Fixtures</NavLink>
    </div>


export default BrowseProductsLinksContainer;