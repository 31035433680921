import React from 'react';
import ColorsList from './colors-list';


let ColorsContainer = (props) =>
    <div className="colors-container">
            <h4 className="colors-header">Colors Available</h4>
            <ColorsList {...props} />
    </div>


export default ColorsContainer;