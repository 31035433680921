import React from 'react';
import { NavLink } from 'react-router-dom';

let LightingBanner = () =>
    <div className="lighting-banner-row">
        <h3 className="lighting-banner-row-header">
            We Now Sell <NavLink className="lighting-banner-nav-link" to="/lighting-fixtures" >Lighting</NavLink>! Browse Our <NavLink className="lighting-banner-nav-link" to="/lighting-fixtures" >Lighting Section</NavLink>
        </h3>       
        
        {/* <Link className="home-screen-products-nav-link home-screen-nav-link" to={{pathname:"/products", state: {scrollToLightingFixtureProducts: true}}} >Visit Our Lighting Page</Link> */}
    </div>

export default LightingBanner;