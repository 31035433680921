import React from 'react';
import { NavLink } from 'react-router-dom';

let FeaturedCeilingFansCard = (props) =>
    <NavLink 
            onClick={(event) => {
                props.dispatch({type: 'UPDATE_PRODUCTS_SCREEN_PREVIOUS_Y_OFFSET', productsScreenPreviousYOffset: (window.pageYOffset)});
            }} 
            className={`featured-products-card-navlink`} 
            to={`/ceiling-fans/${props.category.name}`}
    >
        <div className={`featured-products-card featured-products-card-${props.category.name}`}>
            <img className={`featured-products-card-image`} src={`${process.env.REACT_APP_API_HOST}/images/` + props.category.imageUrl} alt={props.category.productCategory}/>
            <p className={`featured-products-card-category`}>{props.category.productCategory}</p>
        </div>
    </NavLink>


export default FeaturedCeilingFansCard;