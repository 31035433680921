import React from 'react';
import FanLightDetailsList from './fan-light-details-list';

let FanLightContainer = (props) =>
    <div className="fan-light-container">
            <h4 className="fan-light-header">Light Information</h4>
            <FanLightDetailsList {...props} />
    </div>


export default FanLightContainer;