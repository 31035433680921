import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Router from './router';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducer from './reducer.js';
import 'autotrack';

// window.gtag('create', 'G-M4BNNR069N', 'auto');
// window.gtag('require', 'urlChangeTracker');

// window.ga('create', 'UA-132930353-1', 'auto');
// window.ga('require', 'urlChangeTracker');

let initialState = {
    contactFormNameInput: '',
    contactFormEmailInput: '',
    contactFormMessageInput: '',
    contactFormName: '',
    contactFormEmail: '',
    contactFormMessage: '',
    ceilingFansArray: [],
    productCategoryFoundStatus: '',
    currentCeilingFanFoundStatus: '',
    // currentProductCategory: [],
    currentProductCategory: {
        name: '',
        productCategory: '',
        categoryDescription: '',
        imageUrl: ''
    },
    currentCeilingFanViewed: {
        name: '',
        title: '',
        manufacturer: '',
        manufacturerNameReference: '',
        sizes: [],
        productDescription: '',
        motorType: '',
        handheldRemoteControlAndInWallRemoteControlIncluded: '',
        handheldRemoteControlIncluded: '',
        inWallRemoteControlIncluded: '',
        hardwiredWallControlIncluded: '',
        handheldRemoteControlAvailable: '',
        inWallRemoteControlAvailable: '',
        hardwiredWallControlAvailable: '',
        smartFan: '',
        smartFanAdaptable: '',
        smartFanDetails: '',
        // wifiFunctionalityIncluded: '',
        // bluetoothFunctionalityIncluded: '',
        // wifiAdaptable: '',
        // wifiAdaptableDetails: '',
        locationRating: '',
        airflowRating: '',
        lightIncluded: '',
        lightKitAdaptable: '',
        universalLightKitAdaptable: '',
        lightKitRequired: '',
        lightKitInformation: '',
        lumensRating: '',        
        mixAndMatch: '',
        colors: [],
        shown: '',
        productUrl: '',
        imageUrl: '',
        productCategories: [],
        styles: []
    },
    homeScreenImageGalleryArray: [
        {
            name: 'Barn',
            manufacturer: 'Minka Aire',
            url: `${process.env.REACT_APP_AWS_S3_IMAGE_HOST}homescreen_image_gallery/barn_square.jpg`,
            id: 0
        },
        {
            name: 'Lighting',
            manufacturer: 'Lighting',
            url: `${process.env.REACT_APP_AWS_S3_IMAGE_HOST}homescreen_image_gallery/lighting_square.jpg`,
            id: 1
        },
        {
            name: 'Artemis IV',
            manufacturer: 'Minka Aire',
            url: `${process.env.REACT_APP_AWS_S3_IMAGE_HOST}homescreen_image_gallery/artemis_iv_square.jpg`,
            id: 2
        },
        {
            name: 'Spitfire',
            manufacturer: 'Fanimation',
            url: `${process.env.REACT_APP_AWS_S3_IMAGE_HOST}homescreen_image_gallery/spitfire_square.jpg`,
            id: 3
        },
        {
            name: 'i6',
            manufacturer: 'Big Ass Fans',
            url: `${process.env.REACT_APP_AWS_S3_IMAGE_HOST}homescreen_image_gallery/big_ass_fans_square.jpg`,
            id: 4
        },
        {
            name: 'TriAire Custom',
            manufacturer: 'Fanimation',
            url: `${process.env.REACT_APP_AWS_S3_IMAGE_HOST}homescreen_image_gallery/triaire_square.jpg`,
            id: 5
        }
    ],
    homeScreenImageGalleryCurrent: 0,
    productsByBrandArray: [
        {
            id: 0,
            name: 'maxim-lighting',
            brandName: 'Maxim Lighting',
            fansForBrand: [
                {
                    name: 'ambience',
                    title: 'Ambience',
                    manufacturer: 'Maxim Lighting',
                    imageUrl: 'maxim_lighting/ambience.jpg',
                    description: 'Modern, yet warm the Ambience Chandelier with Fan is finished in a dark bronze exterior and gold paint softly illuminated on the inside. Three uniquely shaped blades silently circulate the air at any of the six adjustable speeds in any indoor or outdoor space. Eight LEDs are nestled within the contoured arms to veil the fan with their glow. A warm and inviting ambiance is just what the Ambience delivers.',
                    sizes: [33.5],
                    location: 'Indoor / Outdoor (Damp)',
                    lumens: '4,200',
                    colors: ['Bronze / Gold'],
                    shown: 'Bronze / Gold',
                    id: 0
                },
                {
                    name: 'bodega-bay',
                    title: 'Bodega Bay',
                    manufacturer: 'Maxim Lighting',
                    imageUrl: 'maxim_lighting/bodega_bay.jpg',
                    description: 'Rings of Ash wood are distressed and then finished In Antique Pecan for that old world feel. Metal accents finished in Anthracite provide a forged iron appearance that complements this rustic design.',
                    sizes: [36],
                    location: 'Indoor / Outdoor (Damp)',
                    lumens: '2,650',
                    colors: ['Antique Pecan / Anthracite'],
                    shown: 'Antique Pecan / Anthracite',
                    id: 1
                },
                {
                    name: 'candella',
                    title: 'Candella',
                    manufacturer: 'Maxim Lighting',
                    imageUrl: 'maxim_lighting/candella.jpg',
                    description: 'A historical reproduction features candle reflectors finished in Gold which creates a dramatic contrast to the Chestnut Bronze outer body. This classic design works well in most traditional decor yet the simple lines allow it to also fit in a transitional setting.',
                    sizes: ['31.25'],
                    location: 'Indoor / Outdoor (Damp)',
                    lumens: '1,680',
                    colors: ['Chestnut Bronze / Gold'],
                    shown: 'Chestnut Bronze / Gold',
                    id: 2
                },
                {
                    name: 'corona',
                    title: 'Corona',
                    manufacturer: 'Maxim Lighting',
                    imageUrl: 'maxim_lighting/corona.jpg',
                    description: 'The Corona Chandelier with fan features a cage of artfully bent Satin Nickel that houses the three innovatively curved fan blades. Its streamlined styling is paired with a high-powered LED ring offering additional downlight while circulation the air.',
                    sizes: [27.25],
                    location: 'Indoor',
                    lumens: '2,000',
                    colors: ['Satin Nickel'],
                    shown: 'Satin Nickel',
                    id: 3
                },
                {
                    name: 'counter',
                    title: 'Counter',
                    manufacturer: 'Maxim Lighting',
                    imageUrl: 'maxim_lighting/counter.jpg',
                    description: 'The Counter Chandelier with fan is functional art at its finest. Bold, sleek and contemporary, the Counter is sure to be the focal point of any kitchen or living area. The fan blades, contained within a cage of four steel curved bars with LED integrated light strips, silently circulate the air at the speed of your preference. The Counter offers modern aviation appeal, solid construction, and a beautiful Satin Nickel finish.',
                    sizes: [34.5],
                    location: 'Indoor',
                    lumens: '3,640',
                    colors: ['Satin Nickel'],
                    shown: 'Satin Nickel',
                    id: 4
                },
                {
                    name: 'solitaire',
                    title: 'Solitaire',
                    manufacturer: 'Maxim Lighting',
                    imageUrl: 'maxim_lighting/solitaire.jpg',
                    description: 'The creme de la creme - the Solitaire. This romantic Chandelier with Fan delivers luxury appeal and timeless sophistication. The Solitaire features curved arms that lift a crown of six candle-style lamps. It is adorned with six large marquise cut crystals and six circular cut crystals that illuminate the surroundings in a regal display of light. This beautiful chandelier with fan is hand-painted in a Silver Mist finish creating a sophisticated wrought-iron appearance.',
                    sizes: [30.75],
                    location: 'Indoor',
                    lumens: '1,680',
                    colors: ['Silver Mist'],
                    shown: 'Silver Mist',
                    id: 5
                },
                {
                    name: 'solstice',
                    title: 'Solstice',
                    manufacturer: 'Maxim Lighting',
                    imageUrl: 'maxim_lighting/solstice.jpg',
                    description: 'The Solstice Chandelier with Fan houses three innovatively curved fan blades within a cage of artfully bent Satin Nickel channel to create a classic form in light. Its architecturally contemporary styling is paired with high-powered LED lighting making it functional and fantastic for transitional and modern settings.',
                    sizes: [34.25],
                    location: 'Indoor',
                    lumens: '5,200',
                    colors: ['Satin Nickel'],
                    shown: 'Satin Nickel',
                    id: 6
                },
                {
                    name: 'tuscany',
                    title: 'Tuscany',
                    manufacturer: 'Maxim Lighting',
                    imageUrl: 'maxim_lighting/tuscany.jpg',
                    description: `The Tuscany's Mediterranean appeal and antique flair makes it perfect for brightening a space while adding a touch of style and beauty. Picturesque and functional, the Tuscany with its ornate metalwork and Driftwood band provide an elegant accent to any rustic, classic or traditional decor. This Chandelier with fan features a hand-painted Driftwood finish along a band that conceals its integrated LED light source, and it is complemented by Iron Ore metal accents. It is ideal for use in any indoor or outdoor dining and living areas.`,
                    sizes: [30.25],
                    location: 'Indoor / Outdoor (Damp)',
                    lumens: '2,000',
                    colors: ['Driftwood / Iron Ore'],
                    shown: 'Driftwood / Iron Ore',
                    id: 7
                },
                {
                    name: 'weave',
                    title: 'Weave',
                    manufacturer: 'Maxim Lighting',
                    imageUrl: 'maxim_lighting/weave.jpg',
                    description: 'Bands of metal are woven on a frame and finished in Bronzed Gilt on the outside and a contrasting Gold Leaf finish on the inside. The result is a warm glow of LED light that is cast into the room with a unique design that enhances the beauty of the room.',
                    sizes: [33],
                    location: 'Indoor',
                    lumens: '2,703',
                    colors: ['Bronze Gilt / Gold'],
                    shown: 'Bronze Gilt / Gold',
                    id: 8
                }
            ],
            brandDescription: ''
        }
    ],
    productCategoriesArray: [],
    lightingFixtureCategoriesArray: [],
    lightingFixturesArray: [],
    lightingFixtureCategoryFoundStatus: '',
    currentLightingFixtureCategory: {
        name: '',
        lightingFixtureCategory: '',
        categoryDescription: '',
        imageUrl: ''
    },
    currentLightingFixtureViewed: {
        name: '',
        title: '',
        manufacturer: '',
        manufacturerNameReference: '',
        productDescription: '',
        fixtureHeight: '',
        fixtureLength: '',
        fixtureWidth: '',
        maxOverallHeight: '',
        availableInOtherSizes: '',
        locationRating: '',
        colors: [],
        shown: '',
        numberOfBulbs: '',
        bulbType: '',
        bulbWattage: '',
        lumensRating: '',
        productUrl: '',
        partNumber: '',
        imageUrl: '',
        productCategories: [],
        styles: []
    },
    currentLightingFixtureFoundStatus: '',
    productsScreenPreviousYOffset: 0,
    returningProductsScreenYOffset: 0,
    categoryScreenPreviousYOffset: 0,
    returningCategoryScreenYOffset: 0,
    previousScreenUrl: '',
    currentScreenUrl: '',
    scrollRestorationIsManual: false
};

let store = createStore(
    reducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

let App = 
    <Provider store={store}>
        <Router />
    </Provider>

ReactDOM.render(App, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
