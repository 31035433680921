import React from 'react';
import Header from './header';
import Footer from './footer';
import NavBar from './nav-bar';
import CeilingFansByBrandContainer from './ceiling-fans-by-brand-container';
import PageNotFoundScreen from './page-not-found-screen';
import LoadingScreen from './loading-screen';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import 'autotrack';

class CeilingFansByBrandScreen extends React.Component {
    componentDidMount() {
        // window.scrollTo(0, this.props.returningCategoryScreenYOffset);
        // this.props.dispatch({type: 'RESET_RETURNING_PRODUCTS_SCREEN_Y_OFFSET', returningProductsScreenYOffset: 0});
        // this.props.dispatch({type: 'RESET_RETURNING_CATEGORY_SCREEN_Y_OFFSET', returningCategoryScreenYOffset: 0});
        // this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname})
        // window.ga('create', 'UA-132930353-1', 'auto');
        // window.ga('require', 'urlChangeTracker');
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
        fetch(`${process.env.REACT_APP_API_HOST}/api/bybrand/ceilingfans/${this.props.match.params.brand}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        })
            .then(results=> {
                return results.json();
            })
            .then(results => {
                if (results === 'No Results') {
                    this.props.dispatch({type: 'SET_CATEGORY_FOUND_STATUS', productCategoryFoundStatus: false})
                } else {
                    let ceilingFanResults = results.ceilingFanResults;
                    let manufacturerDescription = results.manufacturerDescription
                    this.props.dispatch({type: 'SET_CURRENT_MANUFACTURER_DESCRIPTION', currentManufacturerDescription: manufacturerDescription});
                    this.props.dispatch({type: 'SET_CEILING_FANS_ARRAY', ceilingFansArray: ceilingFanResults});
                    this.props.dispatch({type: 'SET_CATEGORY_FOUND_STATUS', productCategoryFoundStatus: true});
                }
            })
        setTimeout (() => {
            window.scrollTo(0, this.props.returningCategoryScreenYOffset);
            this.props.dispatch({type: 'RESET_RETURNING_PRODUCTS_SCREEN_Y_OFFSET', returningProductsScreenYOffset: 0});
            this.props.dispatch({type: 'RESET_RETURNING_CATEGORY_SCREEN_Y_OFFSET', returningCategoryScreenYOffset: 0});
            this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname})
        }, 200)
    }
    render() {
        return <div className="products-by-brand-screen">
            {(this.props.productCategoryFoundStatus === true)?
                <div>
                    <Helmet>
                        <title>Atlanta Ceiling Fans : Ceiling Fans By {(this.props.currentManufacturerDescription.name)}</title>
                        <meta name="description" content="Shop Atlanta Ceiling Fans in Roswell, Georgia for the best selection of ceiling fans, parts and accessories.  Our showroom has over 150 designer ceiling fans on display many of which are in stock and ready to take home today.  We carry a large selection of indoor and outdoor designer ceiling fans in many styles (Modern, Farmhouse, Traditional, Tropical) and sizes (26” small up to 99” large fans)." />
                        <meta name="twitter:title" content="Atlanta Ceiling Fans : Ceiling Fans" />
                        <meta property="og:title" content="Atlanta Ceiling Fans : Ceiling Fans" />
                        <meta property="og:description" content="Shop Atlanta Ceiling Fans in Roswell, Georgia for the best selection of ceiling fans, parts and accessories.  Our showroom has over 150 designer ceiling fans on display many of which are in stock and ready to take home today.  We carry a large selection of indoor and outdoor designer ceiling fans in many styles (Modern, Farmhouse, Traditional, Tropical) and sizes (26” small up to 99” large fans)." />
                        <meta property="og:image" content={`${process.env.REACT_APP_API_HOST}/logos/logo_header.svg`} />
                    </Helmet>
                    <Header />
                    <NavBar />
                    <CeilingFansByBrandContainer {...this.props} />
                    <Footer />
                </div>
            :
            (this.props.productCategoryFoundStatus === false)?
                <PageNotFoundScreen {...this.props}/>
            :
                <LoadingScreen {...this.props}/>
            }
        </div>
    }
}

let ConnectedCeilingFansByBrandScreen = connect(state=> {
    return {
        productsByBrandArray: state.productsByBrandArray,
        productCategoriesArray: state.productCategoriesArray,
        ceilingFansArray: state.ceilingFansArray,
        currentManufacturerDescription: state.currentManufacturerDescription,
        productCategoryFoundStatus: state.productCategoryFoundStatus,
        // productsScreenPreviousYOffset: state.productsScreenPreviousYOffset,
        returningProductsScreenYOffset: state.returningProductsScreenYOffset,
        returningCategoryScreenYOffset: state.returningCategoryScreenYOffset,
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl
    }
})(CeilingFansByBrandScreen);

export default ConnectedCeilingFansByBrandScreen;