import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

let ProductsBrandsLogo = (props) =>
    <React.Fragment>
        {(props.brand.navLink === true)?
            <li className="products-brands-logo" >
                <NavLink 
                    onClick={(event) => {
                        props.dispatch({type: 'UPDATE_PRODUCTS_SCREEN_PREVIOUS_Y_OFFSET', productsScreenPreviousYOffset: (window.pageYOffset)});
                    }} 
                    className="products-brands-logo-anchor" 
                    to={props.brand.url}
                >
                    <img className='products-brands-logo-image' src={props.brand.photoUrl} alt={props.brand.brand} />
                </NavLink>
            </li>
        :
            <li className="products-brands-logo" >
                <a className="products-brands-logo-anchor" href={props.brand.url} target="_blank" rel="noreferrer noopener" >
                    <img className='products-brands-logo-image' src={props.brand.photoUrl} alt={props.brand.brand} />
                </a>
            </li>
        }
    </React.Fragment>

let ConnectedProductsBrandsLogo = connect(state=> {
    return {
        categoryScreenPreviousYOffset: state.categoryScreenPreviousYOffset,
        returningProductsScreenYOffset: state.returningProductsScreenYOffset,
        productsScreenPreviousYOffset: state.productsScreenPreviousYOffset,
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl
    }
})(ProductsBrandsLogo);

export default ConnectedProductsBrandsLogo;


// export default ProductsBrandsLogo;