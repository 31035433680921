import React from 'react';

let HomeScreenDescription = () =>
    <div className="home-screen-description">
        <p className="home-screen-description-paragraph">
            Visit our showroom to experience a world of ceiling fans. SEE, TOUCH, and FEEL the difference in a high quality ceiling fan. Ask all of your questions and our experienced staff will help you find your perfect fan. We are constantly getting new models in to give our customers the best selection in Atlanta. When you need a ceiling fan come to Atlanta Ceiling Fans where FANS are our business.
        </p>
    </div>


export default HomeScreenDescription;