import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

let CeilingFansByBrandFansCard = (props) =>
    <div className={`category-fan-card category-fan-card-${props.fan.name}`}>
        <NavLink 
            onClick={(event) => {
                props.dispatch({type: 'UPDATE_CATEGORY_SCREEN_PREVIOUS_Y_OFFSET', categoryScreenPreviousYOffset: (window.pageYOffset)});
                props.dispatch({type: 'SET_CURRENT_CEILING_FAN_VIEWED', currentCeilingFanViewed: props.fan})
            }} 
            className="category-fan-card-nav-link" 
            to={`/ceiling-fans/by-brand/${props.currentManufacturerDescription.nameReference}/${props.fan.name}`}
        >
            <img className="category-fan-card-image" src={props.fan.imageUrl} alt={props.fan.manufacturer + ' ' + props.fan.title}/>
            <p className="category-fan-card-name">{props.fan.title}</p>
        </NavLink>
    </div>


// export default CeilingFansByBrandFansCard;

let ConnectedCeilingFansByBrandFansCard = connect(state=> {
    return {
        categoryScreenPreviousYOffset: state.categoryScreenPreviousYOffset,
        currentCeilingFanViewed: state.currentCeilingFanViewed,
        productsByBrandArray: state.productsByBrandArray,
        productCategoriesArray: state.productCategoriesArray,
        ceilingFansArray: state.ceilingFansArray,
        currentManufacturerDescription: state.currentManufacturerDescription,
        productCategoryFoundStatus: state.productCategoryFoundStatus,
        productsScreenPreviousYOffset: state.productsScreenPreviousYOffset,
        returningProductsScreenYOffset: state.returningProductsScreenYOffset,
        returningCategoryScreenYOffset: state.returningCategoryScreenYOffset,
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl
    }
})(CeilingFansByBrandFansCard);

export default ConnectedCeilingFansByBrandFansCard;