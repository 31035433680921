import React from 'react';

let PartNumberList = (props) =>
    <ul className="part-number-list">
            <li className="part-number-list-item">
                {props.currentLightingFixtureViewed.partNumber}
            </li>
    </ul>


export default PartNumberList;