import React from 'react';

let ContactUsCard = () =>
    <div className="contact-us-card">
        <h3 className="contact-us-card-header">
            CONTACT US
        </h3>
        <div className="contact-us-card-information">
            <p className="contact-us-card-information-address">
                1160 Alpharetta Street <br />
                Suite A <br />
                Roswell, GA 30075
            </p>
            <div className="contact-us-card-information-phone-email-container">
                <div className="contact-us-card-information-phone-email-headers">
                    <span className="contact-us-card-information-phone-header">
                        Phone:
                    </span>
                    <span className="contact-us-card-information-email-header">
                        Email:
                    </span>

                </div>
                <div className="contact-us-card-information-phone-email-information">
                    <span className="contact-us-card-information-phone-information">
                        770-998-6812
                    </span>
                    <a className="contact-us-card-information-email-information" href="mailto: sales@atlantaceilingfans.com">
                        sales@atlantaceilingfans.com
                    </a>

                </div>
            </div>
            <div className="contact-us-card-information-operation-hours">
                <p className="contact-us-card-information-operation-hours-header">
                    Hours of Operation:
                </p>
                <div className="contact-us-card-information-operation-hours-container">
                    <div className="contact-us-card-information-days">
                        <span>Mon-Fri</span>
                        <span>Sat</span>
                        <span>Sun</span>
                    </div>
                    <div className="contact-us-card-information-hours">
                        <span>9:00 AM - 5:00 PM</span>
                        <span>10:00 AM - 5:00 PM</span>
                        <span>Closed</span>
                    </div>
                </div>
            </div>
        </div>
    </div>


export default ContactUsCard;