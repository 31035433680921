import React from 'react';

let FanAirflowRatingListItem = (props) =>
    <React.Fragment>
        {(props.currentCeilingFanViewed.airflowRating.length > 0)?
            props.currentCeilingFanViewed.airflowRating.map((airflowRating, index) =>
            <li className="fan-airflow-rating-list-item">
                {(airflowRating)} CFM on High
            </li>
            )
            
        :
            <li className="fan-airflow-rating-list-item">
                Call or email us for details.
            </li>
        }
    </React.Fragment>

export default FanAirflowRatingListItem;