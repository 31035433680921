import React from 'react';
import SizesContainer from './sizes-container';
import ColorsContainer from './colors-container';
import FanDescriptionContainer from './fan-description-container';
import FanLocationContainer from './fan-location-container';
import FanLightContainer from './fan-light-container';
import FanMotorTypeContainer from './fan-motor-type-container';
import FanControlTypeContainer from './fan-control-type-container';
import FanLocationRatingContainer from './fan-location-rating-container';
import FanAirflowRatingContainer from './fan-airflow-rating-container';

let FanDetailsRow = (props) =>
    <div className="fan-details-row">
        <div className="fan-details-image-container">
            <img className="fan-details-image" src={props.currentCeilingFanViewed.imageUrl} alt={props.currentCeilingFanViewed.manufacturer + ' ' + props.currentCeilingFanViewed.title} />
            <p className="fan-details-image-caption">
                {(props.currentCeilingFanViewed.mixAndMatch === true)?
                    `Shown in ${props.currentCeilingFanViewed.shown.body} Body ${
                        (props.currentCeilingFanViewed.shown.light)?
                            `, ${props.currentCeilingFanViewed.shown.blades} Blades, and ${props.currentCeilingFanViewed.shown.light}`
                        :
                            ` and ${props.currentCeilingFanViewed.shown.blades} Blades`
                    }`
                :
                    `Shown in ${props.currentCeilingFanViewed.shown}`    
                }
            </p>
        </div>
        <div className="fan-details-information-container">
            {(() => {
                if (props.currentCeilingFanViewed.productDescription) {
                    return (
                        <FanDescriptionContainer {...props}/>
                    )
                }
            })()}
            <SizesContainer {...props} />
            <FanMotorTypeContainer {...props} />
            <FanAirflowRatingContainer {...props} />
            <FanControlTypeContainer {...props} />
            <FanLocationRatingContainer {...props} />
            <ColorsContainer {...props} />
            <FanLightContainer {...props} />
            {(() => {
                if (props.currentCeilingFanViewed.location) {
                    return (
                        <FanLocationContainer {...props}/>
                    )
                }
            })()}
        </div>
    </div>


export default FanDetailsRow;