import React from 'react';
import Header from './header';
import NavBar from './nav-bar';
import { connect } from 'react-redux';
import Footer from './footer';
import FanDetailsContainer from './fan-details-container';
import PageNotFoundScreen from './page-not-found-screen';
import Helmet from 'react-helmet';
import LoadingScreen from './loading-screen';
import 'autotrack';


class FanDetailsScreen extends React.Component {
    componentDidMount() {
        this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname});
        window.scrollTo(0, 0);
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
        if (this.props.currentCeilingFanViewed.name === '' || this.props.currentCeilingFanViewed.name !== this.props.match.params.id) {
            fetch(`${process.env.REACT_APP_API_HOST}/api/ceilingfandetails/${this.props.match.params.category}/${this.props.match.params.id}`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            })
                .then(results=> {
                    return results.json();
                })
                .then(results => {
                    if (results === 'No Results') {
                        this.props.dispatch({type: 'SET_CURRENT_CEILING_FAN_FOUND_STATUS', currentCeilingFanFoundStatus: false})
                    } else {
                        let ceilingFanResults = results.ceilingFanResults;
                        let productCategoryDescription = results.productCategoryDescription[0];
                        this.props.dispatch({type: 'SET_CURRENT_CEILING_FAN_FOUND_STATUS', currentCeilingFanFoundStatus: true});
                        this.props.dispatch({type: 'SET_CURRENT_PRODUCT_CATEGORY', currentProductCategory: productCategoryDescription});
                        this.props.dispatch({type: 'SET_CURRENT_CEILING_FAN_VIEWED', currentCeilingFanViewed: ceilingFanResults});
                        this.props.dispatch({type: 'SET_CATEGORY_FOUND_STATUS', productCategoryFoundStatus: true});
                        fetch(`${process.env.REACT_APP_API_HOST}/api/ceilingfans/${this.props.match.params.category}`, {
                            method: 'GET',
                            headers: {'Content-Type': 'application/json'}
                        })
                            .then(results=> {
                                return results.json();
                            })
                            .then(results => {
                                let ceilingFanResults = results.ceilingFanResults;
                                this.props.dispatch({type: 'SET_CEILING_FANS_ARRAY', ceilingFansArray: ceilingFanResults})
                            })
                    }
                })
        } 
    };
    render() {
        return <div className="fan-details-screen">
            {(this.props.match.params.id === this.props.currentCeilingFanViewed.productUrl)?
                <div>
                    <Helmet>
                        <title>
                            Atlanta Ceiling Fans : {this.props.currentCeilingFanViewed.title}
                        </title>
                        <meta name="description" content="Visit our blog page which offers helpful ceiling fan tips and information to make your buying process a breeze." />
                        <meta name="twitter:title" content="Atlanta Ceiling Fans" />
                        <meta property="og:title" content="Atlanta Ceiling Fans" />
                        <meta property="og:description" content="Visit our blog page which offers helpful ceiling fan tips and information to make your buying process a breeze." />
                        <meta property="og:image" content={`${process.env.REACT_APP_API_HOST}/logos/logo_header.svg`} />
                    </Helmet>
                    <Header />
                    <NavBar />
                    <FanDetailsContainer {...this.props} />
                    <Footer />
                </div>
            :
            (this.props.currentCeilingFanFoundStatus === false)?
                <PageNotFoundScreen {...this.props}/>
            :
                <LoadingScreen {...this.props}/>
            }
        </div>
    }
}

let ConnectedFanDetailsScreen = connect(state=> {
    return {
        productCategoriesArray: state.productCategoriesArray,
        ceilingFansArray: state.ceilingFansArray,
        currentProductCategory: state.currentProductCategory,
        currentCeilingFanFoundStatus: state.currentCeilingFanFoundStatus,
        currentCeilingFanViewed: state.currentCeilingFanViewed,
        categoryScreenPreviousYOffset: state.categoryScreenPreviousYOffset,
        returningCategoryScreenYOffset: state.returningCategoryScreenYOffset,
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl
    }
})(FanDetailsScreen);

export default ConnectedFanDetailsScreen;