import React from 'react';
import postContactFormMessage from './postContactFormMessage';

let ContactUsForm = (props) =>
    <div className="contactu-us-form-container" id="send-us-a-message">
        <h3 className="contact-us-form-header">
            Send Us A Message
        </h3>
        <form
            onSubmit={ (event) => {
                event.preventDefault();
                let contactFormMessage = {
                    contactFormName: props.contactFormNameInput,
                    contactFormEmail: props.contactFormEmailInput,
                    contactFormMessage: props.contactFormMessageInput
                };
                postContactFormMessage(contactFormMessage, props);
            }} 
            className="contact-us-form"
            >
            <p className="contact-us-form-input-header">
                <label for="customer-name">Name</label>
                <input 
                    onChange={ (event) => {
                        let value = event.target.value;
                        props.dispatch({type: 'SET_CONTACT_FORM_NAME_INPUT', contactFormNameInput: value})
                    }}
                    id="customer-name"
                    className="contact-us-form-input" 
                    name="customer-name"
                    placeholder="Name" 
                    value={props.contactFormNameInput}
                    required
                />
            </p>
            <p className="contact-us-form-input-header">
                <label for="customer-email-address">Email</label>
                <input 
                    onChange={ (event) => {
                        let value = event.target.value;
                        props.dispatch({type: 'SET_CONTACT_FORM_EMAIL_INPUT', contactFormEmailInput: value})
                    }}
                    id="customer-email-address"
                    className="contact-us-form-input" 
                    name="customer-email-address"
                    type="email" 
                    placeholder="Email" 
                    value={props.contactFormEmailInput}
                    required
                />
            </p>
            <p className="contact-us-form-input-header">
                <label for="customer-message">Message</label>
                <textarea 
                    onChange={ (event) => {
                        let value = event.target.value;
                        props.dispatch({type: 'SET_CONTACT_FORM_MESSAGE_INPUT', contactFormMessageInput: value})
                    }}
                    id="customer-message"
                    className="contact-us-form-input contact-us-form-message-input" 
                    name="customer-message"
                    placeholder="Message" 
                    value={props.contactFormMessageInput}
                    required
                />
            </p>
            <input className="contact-us-form-submit-button" type="submit" value="Send"/>
        </form>
    </div>


export default ContactUsForm;