import React from 'react';
import FanLocationRatingList from './fan-location-rating-list';

let FanLocationRatingContainer = (props) =>
    <div className="fan-location-rating-container">
            <h4 className="fan-location-rating-header">Location Rating</h4>
            <FanLocationRatingList {...props} />
    </div>


export default FanLocationRatingContainer;