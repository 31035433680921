import React from 'react';
import Header from './header';
import NavBar from './nav-bar';
import Footer from './footer';
import PrivacyPolicyContainer from './privacy-policy-container';

class PrivacyPolicyScreen extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
    }
    render() {
        return <div>
            <Header />
            <NavBar />
            <PrivacyPolicyContainer />
            <Footer />
        </div>
    }
}


export default PrivacyPolicyScreen;