import React from 'react';
import CategoryDescription from './category-description';
import CategoryFansContainer from './category-fans-container';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

let CategoryContainer = (props) => 
    <div className="category-container">
        <CategoryDescription {...props}/>
        <CategoryFansContainer 
            {...props}
            // categoryFans={Object.keys(props.categoriesFansObject).find(categoryFans=> 
            //     (props.category.name === categoryFans)
            // )}
        />
        <NavLink 
            onClick={(event) => {
                props.dispatch({type: 'UPDATE_RETURNING_PRODUCTS_SCREEN_Y_OFFSET', returningProductsScreenYOffset: props.productsScreenPreviousYOffset});
                props.dispatch({type: 'RESET_PRODUCTS_SCREEN_PREVIOUS_Y_OFFSET', productsScreenPreviousYOffset: 0});
                props.dispatch({type: 'SET_CATEGORY_FOUND_STATUS', productCategoryFoundStatus: ''})
                props.dispatch({type: 'SET_CEILING_FANS_ARRAY', ceilingFansArray: []})
                props.dispatch({type: 'SET_CURRENT_PRODUCT_CATEGORY', currentProductCategory: {
                    name: '',
                    productCategory: '',
                    categoryDescription: '',
                    imageUrl: ''
                }})
            }}
            className="category-screen-back-to-products-screen-nav-link" 
            to={`/ceiling-fans`}
        >
            {`Back to Ceiling Fan Categories`}
        </NavLink>
    </div>


let ConnectedCategoryContainer = connect(state=> {
    return {
        returningProductsScreenYOffset: state.returningProductsScreenYOffset,
        productsScreenPreviousYOffset: state.productsScreenPreviousYOffset
    }
})(CategoryContainer);

export default ConnectedCategoryContainer;