import React from 'react';
import Header from './header';
import NavBar from './nav-bar';
import Footer from './footer';
import { connect } from 'react-redux';
import CategoryContainer from './category-container';
import PageNotFoundScreen from './page-not-found-screen';
import Helmet from 'react-helmet';
import LoadingScreen from './loading-screen';
import 'autotrack';


class FeaturedProductsCategoryScreen extends React.Component {
    componentDidMount() {
        // window.onresize =  window.scrollTo(0, this.props.returningCategoryScreenYOffset);
        // const targetNode = document.getElementById('category-screen');
        // const config = { attributes: true, childList: true, subtree: true };
        // const windowScrollTo = () => {
        //     window.scrollTo(0, this.props.returningCategoryScreenYOffset)
        // }
        // const observer = new MutationObserver(windowScrollTo);
        // observer.observe(targetNode, config);
        // window.scrollTo(0, this.props.returningCategoryScreenYOffset);
        // setTimeout(() => {
        //     window.scrollTo(0, this.props.returningCategoryScreenYOffset);
        //     this.props.dispatch({type: 'RESET_RETURNING_CATEGORY_SCREEN_Y_OFFSET', returningCategoryScreenYOffset: 0});
        //     this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname});
        // }, 20);
        // this.props.dispatch({type: 'RESET_RETURNING_CATEGORY_SCREEN_Y_OFFSET', returningCategoryScreenYOffset: 0});
        // this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname});
        // window.ga('create', 'UA-132930353-1', 'auto');
        // window.ga('require', 'urlChangeTracker');
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
        // window.history.scrollRestoration = 'manual';
        // console.log(this.props)
        if (this.props.ceilingFansArray <1 || this.props.currentProductCategory.name !== this.props.match.params.id) {
            // console.log('fetching')
            fetch(`${process.env.REACT_APP_API_HOST}/api/ceilingfans/${this.props.match.params.id}`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            })
                .then(results=> {
                    return results.json();
                })
                .then(results => {
                    if (results === 'No Results') {
                        this.props.dispatch({type: 'SET_CATEGORY_FOUND_STATUS', productCategoryFoundStatus: false})
                    } else {
                        let ceilingFanResults = results.ceilingFanResults;
                        let productCategoryDescription = results.productCategoryDescription[0]
                        this.props.dispatch({type: 'SET_CEILING_FANS_ARRAY', ceilingFansArray: ceilingFanResults});
                        this.props.dispatch({type: 'SET_CURRENT_PRODUCT_CATEGORY', currentProductCategory: productCategoryDescription});
                        this.props.dispatch({type: 'SET_CATEGORY_FOUND_STATUS', productCategoryFoundStatus: true});
                    }
                })
            }
        setTimeout (() => {
            window.scrollTo(0, this.props.returningCategoryScreenYOffset);
            this.props.dispatch({type: 'RESET_RETURNING_CATEGORY_SCREEN_Y_OFFSET', returningCategoryScreenYOffset: 0});
            this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname});
        }, 150);
        // window.scrollTo(0, this.props.returningCategoryScreenYOffset);
        // this.props.dispatch({type: 'RESET_RETURNING_CATEGORY_SCREEN_Y_OFFSET', returningCategoryScreenYOffset: 0});
        // this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname});
    }
    render() {    
        return <div className="category-screen" id="category-screen">
            {(this.props.productCategoryFoundStatus === true)?
                <div>
                    <Helmet>
                        <title>
                            Atlanta Ceiling Fans : {(this.props.currentProductCategory.productCategory)}
                        </title>
                        <meta name="description" content="Visit our blog page which offers helpful ceiling fan tips and information to make your buying process a breeze." />
                        <meta name="twitter:title" content="Atlanta Ceiling Fans" />
                        <meta property="og:title" content="Atlanta Ceiling Fans" />
                        <meta property="og:description" content="Visit our blog page which offers helpful ceiling fan tips and information to make your buying process a breeze." />
                        <meta property="og:image" content={`${process.env.REACT_APP_API_HOST}/logos/logo_header.svg`} />
                    </Helmet>
                    <Header />
                    <NavBar />
                    <CategoryContainer {...this.props} />
                    <Footer />
                </div>
            :
            (this.props.productCategoryFoundStatus === false)?
                <PageNotFoundScreen {...this.props}/>
            :
                <LoadingScreen {...this.props}/>
            }
        </div>
    }
}


let ConnectedFeaturedProductsCategoryScreen = connect(state=> {
    return {
        productCategoriesArray: state.productCategoriesArray,
        ceilingFansArray: state.ceilingFansArray,
        currentProductCategory: state.currentProductCategory,
        productCategoryFoundStatus: state.productCategoryFoundStatus,
        productsScreenPreviousYOffset: state.productsScreenPreviousYOffset,
        returningProductsScreenYOffset: state.returningProductsScreenYOffset,
        categoryScreenPreviousYOffset: state.categoryScreenPreviousYOffset,
        returningCategoryScreenYOffset: state.returningCategoryScreenYOffset,
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl    
    }
})(FeaturedProductsCategoryScreen);

export default ConnectedFeaturedProductsCategoryScreen;