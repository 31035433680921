import React from 'react';
import LightingFixtureLocationRatingList from './lighting-fixture-location-rating-list';

let LightingFixtureLocationRatingContainer = (props) =>
    <div className="fan-location-rating-container">
            <h4 className="fan-location-rating-header">Location Rating</h4>
            <LightingFixtureLocationRatingList {...props} />
    </div>


export default LightingFixtureLocationRatingContainer;