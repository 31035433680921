import React from 'react';

let Map = () =>
    <div className="contact-us-map-container">
        <iframe
            className="contact-us-map"
            title="Roswell Fan City Map"
            width="100%"
            height="400"
            frameBorder="0" 
            style={{border:0}}
            // gestureHandling="cooperative"
            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API}
            &q=place_id:ChIJ86tEGVZz9YgRKfPA_QeaTHI`} allowFullScreen>
        </iframe>
    </div>

export default Map;