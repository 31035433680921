import React from 'react';

let FanDecriptionContainer = (props) =>
    <div className="fan-description-container">
        <p>
            {props.currentCeilingFanViewed.productDescription}
        </p>
    </div>


export default FanDecriptionContainer;