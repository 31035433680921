import React from 'react';

let AboutUsCard = () =>
    <div className="about-us-card home-screen-list-card">
        <div className="about-us-image-container">
            <img 
                className="about-us-image" 
                src={`${process.env.REACT_APP_API_HOST}/images/odyn_84.jpg`} 
                alt="84 Inch Odyn" 
            />
        </div>
        <div className="about-us-container home-screen-list-card-container">
            <h3 className="about-us-container-header">
                ABOUT US
            </h3>
            <p className="about-us-description">
                {`Atlanta Ceiling Fans is a local family owned and operated business. 
                We have over 25 years of experience in the ceiling fan industry. 
                Ceiling fans are our passion and we are excited to share our knowledge with you. 
                We are here to answer your questions and be your personal search engine. 
                Our business is open 6 days a week. 
                Come and visit our showroom today!`}
            </p>
        </div>
    </div>


export default AboutUsCard;