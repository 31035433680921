import React from 'react';

let ColorsList = (props) =>
    <React.Fragment>
        {(props.currentCeilingFanViewed.mixAndMatch === true)?
            <ul className="colors-list">
                Mix and Match Motor Color and Blade Color to Create Your Own Combination
                <li className="colors-list-item-body">
                    Body:
                    <ul className="colors-list-body">
                        {(props.currentCeilingFanViewed.colors.body.map((bodyColor, index)=>
                            (props.currentCeilingFanViewed.shown.body === bodyColor)?
                                <li className="colors-list-body-color-list-item colors-list-body-color-list-item-shown" key={index}>
                                    {`${bodyColor} (shown)`}
                                </li>
                            :
                                <li className="colors-list-body-color-list-item" key={index}>
                                    {`${bodyColor}`}
                                </li>
                        ))}
                    </ul>
                </li>
                <li className="colors-list-item-blades">
                    Blades:
                    <ul className="colors-list-blades">
                        {(props.currentCeilingFanViewed.colors.blades.map((bladeColor, index)=>
                            (props.currentCeilingFanViewed.shown.blades === bladeColor)?
                                <li className="colors-list-blades-color-list-item colors-list-blades-color-list-item-shown" key={index}>
                                    {`${bladeColor} (shown)`}
                                </li>
                            :
                                <li className="colors-list-blades-color-list-item" key={index}>
                                    {`${bladeColor}`}
                                </li>    
                        ))}
                    </ul>
                </li>
                {(props.currentCeilingFanViewed.colors.light)?
                    <li className="colors-list-item-light">
                        Light:
                        <ul className="colors-list-light">
                            {(props.currentCeilingFanViewed.colors.light.map((light, index)=>
                                (props.currentCeilingFanViewed.shown.light === light)?
                                    <li className="colors-list-light-list-item colors-list-light-list-item-shown" key={index}>
                                        {`${light} (shown)`}
                                    </li>
                                :
                                    <li className="colors-list-light-list-item" key={index}>
                                        {`${light}`}
                                    </li>    
                            ))}
                        </ul>
                    </li>
                :
                    <li className="colors-list-item-light">
                        Light:
                        <ul className="colors-list-light">
                            <li className="colors-list-light-list-item colors-list-light-list-item-shown">
                                No Light Option
                            </li>
                        </ul>
                    </li>
                }
            </ul>
            :
            <ul className="colors-list">
                {(props.currentCeilingFanViewed.colors.map((color, index)=>
                    (props.currentCeilingFanViewed.shown[0] === color)?
                        <li className="colors-list-item colors-list-item-shown" key={index}>
                            {`${color} (shown)`}
                        </li>
                    :
                        <li className="colors-list-item" key={index}>
                            {`${color}`}
                        </li>))}
            </ul>
        }
    </React.Fragment>


export default ColorsList;