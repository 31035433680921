import React from 'react';
import BrandRow from './brand-row';

const brandsArray = [
    {
        brand: 'Fanimation',
        url: 'https://fanimation.com/product-category/fans/',
        navLink: false
    },
    {
        brand: 'Minka Aire',
        url: 'https://www.minkagroup.net/minka-aire/',
        navLink: false
    },
    {
        brand: 'Hunter',
        url: 'https://www.hunterfan.com/',
        navLink: false
    },
    {
        brand: 'Modern Forms',
        url: 'http://modernforms.com/fan/index.html',
        navLink: false
    },
    {
        brand: 'Casablanca',
        url: 'https://www.hunterfan.com/casablanca',
        navLink: false
    },
    {
        brand: 'Big Ass Fans',
        url: 'https://www.bigassfans.com/fans/',
        navLink: false
    },
    {
        brand: 'Craftmade',
        url: 'https://www.craftmade.com/ceiling-fans',
        navLink: false
    },
    {
        brand: 'Matthews Fan Company',
        url: 'http://matthewsfanco.com/',
        navLink: false
    },
    {
        brand: 'Minka-Lavery',
        url: 'https://www.minkagroup.net/minka-lavery/',
        navLink: false,
        brandNameUrl: 'minka-lavery'
    },
    {
        brand: 'The Great Outdoors',
        url: 'https://www.minkagroup.net/the-great-outdoors/',
        navLink: false,
        brandNameUrl: 'the-great-outdoors'
    },
    {
        brand: 'Elk Home',
        url: 'https://www.elkhome.com/',
        navLink: false,
        brandNameUrl: 'elk-home'
    },
    {
        brand: 'Maxim Lighting',
        url: '/ceiling-fans/by-brand/maxim-lighting',
        navLink: true,
        brandNameUrl: 'maxim-lighting'
    }
]

let BrandsList = (props) =>
    <ul className="brands-list">
        {brandsArray.map((brand, index)=>
            <BrandRow brand={brand} key={index} {...props}/>)}
    </ul>


export default BrandsList;