import React from 'react';

let IntroductionRow = () =>
    <div className="introduction-row">
        <h3 className="introduction-row-header">
            Ceiling Fans and Lighting Fixtures
        </h3>
        <p className="introduction-row-paragraph">
        At Atlanta Ceiling Fans we specialize in providing Atlanta with high quality ceiling fans. In store you will find an extensive selection of fans on display and in stock ready to take with you. We also now offer a <span className="introduction-paragraph-emphasis">curated collection of lighting fixtures</span> in store from major lighting brands. With over <span className="introduction-paragraph-emphasis">25 years of experience</span> in the fan business, our staff is ready to help answer your questions and help you find the perfect fan or light fixture for your space.
        </p>
    </div>

export default IntroductionRow;