import React from 'react';
import ProductsBrandsLogo from './products-brands-logo';

const lightingFixturesBrandsArray = [
    {
        name: 'minka-lavery',
        brand: 'Minka-Lavery',
        navLink: false,
        url: 'https://www.minkagroup.net/minka-lavery/',
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/minka_lavery_logo.jpg`
    },
    {
        name: 'the-great-outdoors',
        brand: 'The Great Outdoors',
        navLink: false,
        url: 'https://www.minkagroup.net/the-great-outdoors/',
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/the_great_outdoors_logo.jpg`
    },
    {
        name: 'elk-home',
        brand: 'Elk Home',
        navLink: false,
        url: 'https://www.elkhome.com/',
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/elk_home_logo.jpg`
    },
    {
        name: 'maxim-lighting',
        brand: 'Maxim Lighting',
        navLink: false,
        // url: '/products/by-brand/maxim-lighting',
        url: 'https://www.maximlighting.com/',
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/maxim_lighting_logo.jpg`
    },
    {
        name: 'craftmade',
        brand: 'Craftmade',
        navLink: false,
        url: 'https://www.craftmade.com/ceiling-fans',
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/craftmade_logo.jpg`
    },
    {
        name: 'hunter',
        brand: 'Hunter',
        navLink: false,
        url: 'https://www.hunterfan.com/pages/ceiling-light',
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/hunter_logo.jpg`
    },
    {
        name: 'access-lighting',
        brand: 'Access Lighting',
        navLink: false,
        url: 'https://www.atlantaceilingfans.com/ceiling-fans/by-brand/access-lighting',
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/access_lighting_logo.jpg`
    }
];

let LightingFixturesBrandsContainer = () =>
    <div className="products-brands-container">
        <h2 className="products-brands-container-header">
            Brand Names You'll Love
        </h2>
        <div className="products-brands-logos-container">
            {lightingFixturesBrandsArray.map((brand, index)=>
                <ProductsBrandsLogo brand={brand} key={index} />    
            )}
        </div>
    </div>


export default LightingFixturesBrandsContainer;