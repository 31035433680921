import React from 'react';
import ProductsBrandsLogo from './products-brands-logo';

const brandsArray = [
    {
        name: 'fanimation',
        brand: 'Fanimation Ceiling Fans',
        navLink: false,
        url: 'https://fanimation.com/product-category/fans/',
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/fanimation_logo.jpg`
    },
    {
        name: 'minka-aire',
        brand: 'minkaAire',
        navLink: false,
        url: 'https://www.minkagroup.net/minka-aire/',
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/minka_aire_logo.jpg`
    },
    {
        name: 'hunter',
        brand: 'Hunter',
        navLink: false,
        url: 'https://www.hunterfan.com/',
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/hunter_logo.jpg`
    },
    {
        name: 'modern-forms',
        brand: 'Modern Forms',
        navLink: false,
        url: 'http://modernforms.com/fan/index.html',
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/modern_forms_logo.jpg`
    },
    {
        name: 'casablanca',
        brand: 'Casablanca Fan Company',
        navLink: false,
        url: 'https://www.hunterfan.com/casablanca',
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/casablanca_logo.jpg`
    },
    {
        name: 'big-ass-fans',
        brand: 'Big Ass Fans',
        navLink: false,
        url: 'https://www.bigassfans.com/fans/',
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/big_ass_fans_logo.jpg`
    },
    {
        name: 'craftmade',
        brand: 'Craftmade',
        navLink: false,
        url: 'https://www.craftmade.com/ceiling-fans',
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/craftmade_logo.jpg`
    },
    {
        name: 'matthews-fan-company',
        brand: 'Matthews Fan Company',
        navLink: false,
        url: 'http://matthewsfanco.com/',
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/matthews_fan_company_logo.jpg`
    },
    {
        name: 'maxim-lighting',
        brand: 'Maxim Lighting',
        navLink: true,
        url: `/ceiling-fans/by-brand/maxim-lighting`,
        photoUrl: `${process.env.REACT_APP_API_HOST}/logos/maxim_lighting_logo.jpg`
    },
];

let CeilingFansBrandsContainer = () =>
    <div className="products-brands-container">
        <h2 className="products-brands-container-header">
            Brand Names You'll Love
        </h2>
        <div className="products-brands-logos-container">
            {brandsArray.map((brand, index)=>
                <ProductsBrandsLogo brand={brand} key={index} />    
            )}
        </div>
    </div>


export default CeilingFansBrandsContainer;