import React from 'react';
import Header from './header';
import NavBar from './nav-bar';
import { connect } from 'react-redux';
import Footer from './footer';
import FanDetailsByBrandContainer from './fan-details-by-brand-container';
import PageNotFoundScreen from './page-not-found-screen';
import LoadingScreen from './loading-screen';
import Helmet from 'react-helmet';
import 'autotrack';


class FanDetailsByBrandScreen extends React.Component {
    componentDidMount() {
        this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname});
        window.scrollTo(0, 0);
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
        if (this.props.currentManufacturerDescription === '' || 'undefined' || 'Undefined') {
            fetch(`${process.env.REACT_APP_API_HOST}/api/ceilingfandetailsbybrand/${this.props.match.params.brand}/${this.props.match.params.id}`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            })
                .then(results=> {
                    return results.json();
                })
                .then(results => {
                    if (results === 'No Results') {
                        this.props.dispatch({type: 'SET_CURRENT_CEILING_FAN_FOUND_STATUS', currentCeilingFanFoundStatus: false});
                    } else {
                        this.props.dispatch({type: 'SET_CURRENT_CEILING_FAN_VIEWED', currentCeilingFanViewed: results.ceilingFanResults});
                        this.props.dispatch({type: 'SET_CURRENT_MANUFACTURER_DESCRIPTION', currentManufacturerDescription: results.manufacturerDescription})
                        this.props.dispatch({type: 'SET_CURRENT_CEILING_FAN_FOUND_STATUS', currentCeilingFanFoundStatus: true});
                    }
                })
        } else {

        }
    }
    render() {
        return <div className="fan-details-screen">
            {(this.props.currentCeilingFanFoundStatus === true)?
                <div>
                    <Helmet>
                        <title>
                                Atlanta Ceiling Fans : {(this.props.currentCeilingFanViewed.title)}
                        </title>
                        <meta name="description" content="Visit our blog page which offers helpful ceiling fan tips and information to make your buying process a breeze." />
                        <meta name="twitter:title" content="Atlanta Ceiling Fans" />
                        <meta property="og:title" content="Atlanta Ceiling Fans" />
                        <meta property="og:description" content="Visit our blog page which offers helpful ceiling fan tips and information to make your buying process a breeze." />
                        <meta property="og:image" content={`${process.env.REACT_APP_API_HOST}/logos/logo_header.svg`} />
                    </Helmet>
                    <Header />
                    <NavBar />
                    <FanDetailsByBrandContainer {...this.props} />
                    <Footer />
                </div>
            :
            (this.props.currentCeilingFanFoundStatus === false)?
                <PageNotFoundScreen {...this.props}/>
            :
                <LoadingScreen {...this.props}/>
            }
        </div>
    }
}

let ConnectedFanDetailsByBrandScreen = connect(state=> {
    return {
        productsByBrandArray: state.productsByBrandArray,
        currentCeilingFanViewed: state.currentCeilingFanViewed,
        currentManufacturerDescription: state.currentManufacturerDescription,
        currentCeilingFanFoundStatus: state.currentCeilingFanFoundStatus,
        categoryScreenPreviousYOffset: state.categoryScreenPreviousYOffset,
        returningCategoryScreenYOffset: state.returningCategoryScreenYOffset,
        // productsScreenPreviousYOffset: state.productsScreenPreviousYOffset,
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl
    }
})(FanDetailsByBrandScreen);

export default ConnectedFanDetailsByBrandScreen;