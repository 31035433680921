import React from 'react';
import Header from './header';
import NavBar from './nav-bar';
import LocationRow from './location-row';
import ContactUsRow from './contact-us-row';
import Map from './map';
import Footer from './footer';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import 'autotrack';
import LightingBanner from './lighting-banner';


class ContactUsScreen extends React.Component {
    componentDidMount() {
        this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname});
        // window.ga('create', 'UA-132930353-1', 'auto');
        // window.ga('require', 'urlChangeTracker');
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
    }
    render() {
        return <div>
            <Helmet>
                <title>Atlanta Ceiling Fans : Contact Us</title>
                <meta name="description" content="Call Atlanta Ceiling Fans at 770-998-6812 or visit us at 1160 Alpharetta Street, Suite A, Roswell, GA 30075." />
                <meta name="twitter:title" content="Atlanta Ceiling Fans : Contact Us" />
                <meta property="og:title" content="Atlanta Ceiling Fans : Contact Us" />
                <meta property="og:description" content="Call Atlanta Ceiling Fans at 770-998-6812 or visit us at 1160 Alpharetta Street, Suite A, Roswell, GA 30075." />
                <meta property="og:image" content={`${process.env.REACT_APP_API_HOST}/logos/logo_header.svg`} />
            </Helmet>
            <Header />
            <NavBar />
            <LightingBanner />
            <Map />
            <LocationRow />
            <ContactUsRow {...this.props}/>
            <Footer />
        </div>
    }
};

let ConnectedContactUsScreen = connect(state=> {
    return {
        contactFormNameInput: state.contactFormNameInput,
        contactFormEmailInput: state.contactFormEmailInput,
        contactFormMessageInput: state.contactFormMessageInput,
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl
    }
})(ContactUsScreen);

export default ConnectedContactUsScreen;