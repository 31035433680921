import React from 'react';
import LightingFixtureCategoryDescription from './lighting-fixture-category-description';
import LightingFixtureCategoryCardContainer from './lighting-fixture-category-card-container';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

let LightingFixtureCategoryContainer = (props) => 
    <div className="category-container">
        <LightingFixtureCategoryDescription {...props}/>
        <LightingFixtureCategoryCardContainer {...props} />
        <NavLink 
            onClick={(event) => {
                props.dispatch({type: 'UPDATE_RETURNING_PRODUCTS_SCREEN_Y_OFFSET', returningProductsScreenYOffset: props.productsScreenPreviousYOffset});
                props.dispatch({type: 'RESET_PRODUCTS_SCREEN_PREVIOUS_Y_OFFSET', productsScreenPreviousYOffset: 0});
                props.dispatch({type: 'SET_LIGHTING_FIXTURE_CATEGORY_FOUND_STATUS', lightingFixtureCategoryFoundStatus: ''})
                props.dispatch({type: 'SET_LIGHTING_FIXTURES_ARRAY', lightingFixturesArray: []})
                props.dispatch({type: 'SET_CURRENT_LIGHTING_FIXTURE_CATEGORY', currentLightingFixtureCategory: {
                    name: '',
                    lightingFixtureCategory: '',
                    categoryDescription: '',
                    imageUrl: ''
                }})
            }}
            className="category-screen-back-to-products-screen-nav-link" 
            to={`/lighting-fixtures`} 
        >
            {`Back to Lighting Fixture Categories`}
        </NavLink>
    </div>


let ConnectedLightingFixtureCategoryContainer = connect(state=> {
    return {
        returningProductsScreenYOffset: state.returningProductsScreenYOffset,
        productsScreenPreviousYOffset: state.productsScreenPreviousYOffset
    }
})(LightingFixtureCategoryContainer);

export default ConnectedLightingFixtureCategoryContainer;