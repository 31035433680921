import React from 'react';
import CeilingFansByBrandFansCard from './ceiling-fans-by-brand-fans-card'


let CeilingFansByBrandFansContainer = (props) =>
    <div className="category-fans-container">
        {props.ceilingFansArray.map((fan, index)=>
            <CeilingFansByBrandFansCard fan={fan} key={index} {...props}/>)}
    </div>


export default CeilingFansByBrandFansContainer;