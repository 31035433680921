import React from 'react';
import Header from './header';
import NavBar from './nav-bar';
import Footer from './footer';
import { connect } from 'react-redux';
import LightingFixtureCategoryContainer from './lighting-fixture-category-container';
import PageNotFoundScreen from './page-not-found-screen';
import Helmet from 'react-helmet';
import 'autotrack';
import LightingFixtureLoadingScreen from './lighting-fixture-loading-screen';


class LightingFixtureCategoryScreen extends React.Component {
    componentDidMount() {
        // window.scrollTo(0, this.props.returningCategoryScreenYOffset);
        // this.props.dispatch({type: 'RESET_RETURNING_CATEGORY_SCREEN_Y_OFFSET', returningCategoryScreenYOffset: 0});
        // this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname});
        // window.ga('create', 'UA-132930353-1', 'auto');
        // window.ga('require', 'urlChangeTracker');
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
        if (this.props.lightingFixturesArray <1 || this.props.currentLightingFixtureCategory.name !== this.props.match.params.id) {
            fetch(`${process.env.REACT_APP_API_HOST}/api/lightingfixtures/${this.props.match.params.id}`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            })
                .then(results=> {
                    return results.json();
                })
                .then(results => {
                    if (results === 'No Results') {
                        this.props.dispatch({type: 'SET_LIGHTING_FIXTURE_CATEGORY_FOUND_STATUS', lightingFixtureCategoryFoundStatus: false})
                    } else {
                        let lightingFixtureResults = results.lightingFixtureResults;
                        let lightingFixtureCategoryDescription = results.lightingFixtureCategoryDescription[0];
                        this.props.dispatch({type: 'SET_LIGHTING_FIXTURES_ARRAY', lightingFixturesArray: lightingFixtureResults});
                        this.props.dispatch({type: 'SET_CURRENT_LIGHTING_FIXTURE_CATEGORY', currentLightingFixtureCategory: lightingFixtureCategoryDescription});
                        this.props.dispatch({type: 'SET_LIGHTING_FIXTURE_CATEGORY_FOUND_STATUS', lightingFixtureCategoryFoundStatus: true});
                    }
                })
        }
        setTimeout (() => {
            window.scrollTo(0, this.props.returningCategoryScreenYOffset);
            this.props.dispatch({type: 'RESET_RETURNING_CATEGORY_SCREEN_Y_OFFSET', returningCategoryScreenYOffset: 0});
            this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname});
        }, 200)
        
    }
    render() {    
        return <div className="category-screen">
            {(this.props.lightingFixtureCategoryFoundStatus === true)?
                <div>
                    <Helmet>
                        <title>
                            Atlanta Ceiling Fans : {(this.props.currentLightingFixtureCategory.lightingFixtureCategory)}
                        </title>
                        <meta name="description" content="Visit our blog page which offers helpful ceiling fan tips and information to make your buying process a breeze." />
                        <meta name="twitter:title" content="Atlanta Ceiling Fans" />
                        <meta property="og:title" content="Atlanta Ceiling Fans" />
                        <meta property="og:description" content="Visit our blog page which offers helpful ceiling fan tips and information to make your buying process a breeze." />
                        <meta property="og:image" content={`${process.env.REACT_APP_API_HOST}/logos/logo_header.svg`} />
                    </Helmet>
                    <Header />
                    <NavBar />
                    <LightingFixtureCategoryContainer {...this.props} />
                    <Footer />
                </div>
            :
            (this.props.lightingFixtureCategoryFoundStatus === false)?
                <PageNotFoundScreen {...this.props}/>
            :
                <LightingFixtureLoadingScreen {...this.props}/>
            }
        </div>
    }
}


let ConnectedLightingFixtureCategoryScreen = connect(state=> {
    return {
        lightingFixtureCategoriesArray: state.lightingFixtureCategoriesArray,
        lightingFixturesArray: state.lightingFixturesArray,
        currentLightingFixtureCategory: state.currentLightingFixtureCategory,
        lightingFixtureCategoryFoundStatus: state.lightingFixtureCategoryFoundStatus,
        categoriesFansObject: state.categoriesFansObject,
        productsScreenPreviousYOffset: state.productsScreenPreviousYOffset,
        returningProductsScreenYOffset: state.returningProductsScreenYOffset,
        categoryScreenPreviousYOffset: state.categoryScreenPreviousYOffset,
        returningCategoryScreenYOffset: state.returningCategoryScreenYOffset,
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl    
    }
})(LightingFixtureCategoryScreen);

export default ConnectedLightingFixtureCategoryScreen;