import React from 'react';
import { NavLink } from 'react-router-dom';


let PoliciesTermsCard = (props) => 
    <div className="policies-terms-card">
        <NavLink className="policies-terms-navlink" to="/privacy-policy"  >Privacy Policy</NavLink>
        <NavLink className="policies-terms-navlink" to="/terms-and-conditions" >Terms and Conditions</NavLink>
        <NavLink className="policies-terms-navlink" to="/cookies-policy" >Cookies Policy</NavLink>
    </div>


export default PoliciesTermsCard;