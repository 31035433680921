import React from 'react';
import Header from './header';
import NavBar from './nav-bar';
import Footer from './footer';
import PageNotFoundContainer from './page-not-found-container';
import { connect } from 'react-redux';
import 'autotrack';

class PageNotFoundScreen extends React.Component {
    componentDidMount() {
        // this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname})
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
    }
    render() {
        return <div className="page-not-found-screen">
            <Header />
            <NavBar />
            <PageNotFoundContainer {...this.props}/>
            <Footer />
        </div>
    }
}


let ConnectedPageNotFoundScreen = connect(state=> {
    return {
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl
    }
})(PageNotFoundScreen);

export default ConnectedPageNotFoundScreen;