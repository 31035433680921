import React from 'react';
import { NavLink } from 'react-router-dom';

let CategoryFanCard = (props) =>
    <div className={`category-fan-card category-fan-card-${props.fan.name}`}>
        <NavLink 
            onClick={(event) => {
                props.dispatch({type: 'UPDATE_CATEGORY_SCREEN_PREVIOUS_Y_OFFSET', categoryScreenPreviousYOffset: (window.pageYOffset)});
                props.dispatch({type: 'SET_CURRENT_CEILING_FAN_VIEWED', currentCeilingFanViewed: props.fan})
            }} 
            className="category-fan-card-nav-link" 
            to={`/ceiling-fans/${props.currentProductCategory.name}/${props.fan.productUrl}`}
        >
            {/* <img className="category-fan-card-image" src={`http://d3dvd4avs283wr.cloudfront.net/` + props.fan.imageUrl} alt={props.fan.manufacturer + ' ' + props.fan.title}/> */}
            <img className="category-fan-card-image" src={props.fan.imageUrl} alt={props.fan.manufacturer + ' ' + props.fan.title}/>
            <p className="category-fan-card-name">{props.fan.title}</p>
        </NavLink>
    </div>


export default CategoryFanCard;