import React from 'react';
import FanControlTypeListItem from './fan-control-type-list-item';
import SmartFanControlTypeListItem from './smart-fan-control-type-list-item';

let FanControlTypeList = (props) =>
    <ul className="fan-control-type-list">
        {(props.currentCeilingFanViewed.smartFan === true || props.currentCeilingFanViewed.smartFanAdaptable === true)?
            <React.Fragment>
                <FanControlTypeListItem {...props} />
                <SmartFanControlTypeListItem {...props} />
            </React.Fragment>
        :
        <FanControlTypeListItem {...props} />
        }
    </ul>


export default FanControlTypeList;