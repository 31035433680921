import React from 'react';
import LightingFixtureCategoryCard from './lighting-fixture-category-card';

let LightingFixtureCategoryCardContainer = (props) =>
    <div className="category-fans-container">
        {props.lightingFixturesArray.map((lightingFixture, index)=>
            <LightingFixtureCategoryCard lightingFixture={lightingFixture} key={index} {...props}/>)}
    </div>


export default LightingFixtureCategoryCardContainer;