import React from 'react';
import BrandsList from './brands-list';

let OurBrandsCard = (props) =>
    <div className="our-brands-card home-screen-list-card">
        <img className="our-brands-image" src={`${process.env.REACT_APP_API_HOST}/images/timeless_bnk.jpg`} alt="Minka Aire Timeless BNK" />
        <div className="brands-container home-screen-list-card-container">
            <h3 className="brands-container-header">OUR BRANDS</h3>
            <BrandsList {...props} />
        </div>
    </div>


export default OurBrandsCard;