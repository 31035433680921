import React from 'react';

let SmartFanControlTypeListItem = (props) =>
    <React.Fragment>
        <li className="smart-fan-control-type-list-item">
            {(props.currentCeilingFanViewed.smartFanDetails)}
        </li>
        {/* {(props.currentCeilingFanViewed.wifiFunctionalityIncluded === true)?
            <li className="smart-fan-control-type-list-item">
                WiFi
            </li>
        :
        (props.currentCeilingFanViewed.wifiFunctionalityIncluded === false && props.currentCeilingFanViewed.bluetoothFunctionalityIncluded === true)?
            // (props.currentCeilingFanViewed.bluetoothFunctionalityIncluded === true)?
                <li className="smart-fan-control-type-list-item">
                    Bluetooth
                </li>
            :
            (props.currentCeilingFanViewed.bluetoothFunctionalityIncluded === false && props.currentCeilingFanViewed.wifiAdaptable === true)?
                // (props.currentCeilingFanViewed.wifiAdaptable === true)?
                    <li className="smart-fan-control-type-list-item">
                        WiFi Adaptable
                    </li>
            :
                <li className="smart-fan-control-type-list-item">
                    Call or Email for Smart Fan Compatibility Details
                </li>
        } */}
    </React.Fragment>

export default SmartFanControlTypeListItem;