import React from 'react';
import LightingFixtureDimensionsContainer from './lighting-fixture-dimensions-container';
import LightingFixtureDescriptionContainer from './lighting-fixture-description-container';
import LightingFixtureColorsContainer from './lighting-fixture-colors-container';
import LightingFixtureLocationRatingContainer from './lighting-fixture-location-rating-container';
import LightingFixtureBulbInformationContainer from './lighting-fixture-bulb-information-container';
import PartNumberContainer from './part-number-container';

let LightingFixtureDetailsRow = (props) =>
    <div className="fan-details-row">
        <div className="fan-details-image-container">
            <img className="lighting-fixture-details-image" src={props.currentLightingFixtureViewed.imageUrl} alt={props.currentLightingFixtureViewed.manufacturer + ' ' + props.currentLightingFixtureViewed.title} />
            <p className="fan-details-image-caption">
                Shown in {props.currentLightingFixtureViewed.shown}   
            </p>
        </div>
        <div className="fan-details-information-container">
            {(() => {
                if (props.currentLightingFixtureViewed.productDescription) {
                    return (
                        <LightingFixtureDescriptionContainer {...props}/>
                    )
                }
            })()}
            <LightingFixtureDimensionsContainer {...props} />
            <LightingFixtureBulbInformationContainer {...props} />
            <LightingFixtureColorsContainer {...props} />
            {(() => {
                if (props.currentLightingFixtureViewed.locationRating) {
                    return (
                        <LightingFixtureLocationRatingContainer {...props}/>
                    )
                }
            })()}
            <PartNumberContainer {...props}/>
        </div>
    </div>


export default LightingFixtureDetailsRow;