import React from 'react';
import Header from './header';
import Footer from './footer';
import NavBar from './nav-bar';
import LightingFixturesDescription from './lighting-fixtures-description';
import LightingFixturesBrandsContainer from './lighting-fixtures-brands-container';
import FeaturedLightingFixtureProductsContainer from './featured-lighting-fixture-products-container';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import 'autotrack';



class LightingFixturesProductsScreen extends React.Component {
    componentDidMount() {
        // window.scrollTo(0, this.props.returningProductsScreenYOffset);
        // this.props.dispatch({type: 'RESET_RETURNING_PRODUCTS_SCREEN_Y_OFFSET', returningProductsScreenYOffset: 0});
        // this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname})
        // window.ga('create', 'UA-132930353-1', 'auto');
        // window.ga('require', 'urlChangeTracker');
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
        // window.gtag('set', 'page', `${this.props.location.pathname}`)
        // window.gtag('send', 'pageview');
        if (this.props.lightingFixtureCategoriesArray.length < 1) {
            fetch(`${process.env.REACT_APP_API_HOST}/api/getlightingfixturecategories`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            })
            .then(results=> {
                return results.json();
            })
            .then(results=> {
                let lightingFixtureCategoriesResults = results;
                this.props.dispatch({type: 'SET_LIGHTING_FIXTURE_CATEGORIES_ARRAY', lightingFixtureCategoriesArray: lightingFixtureCategoriesResults})
            })
        }
        setTimeout (() => {
            window.scrollTo(0, this.props.returningProductsScreenYOffset);
            this.props.dispatch({type: 'RESET_RETURNING_PRODUCTS_SCREEN_Y_OFFSET', returningProductsScreenYOffset: 0});
            this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname})
        }, 200)
    }
    render() {
        return <div className="lighting-fixtures-products-screen">
            <Helmet>
                <title>Atlanta Ceiling Fans : Lighting Fixtures</title>
                <meta name="description" content="Shop Atlanta Ceiling Fans in Roswell, Georgia for the best selection of ceiling fans, parts and accessories.  Our showroom has over 150 designer ceiling fans on display many of which are in stock and ready to take home today.  We carry a large selection of indoor and outdoor designer ceiling fans in many styles (Modern, Farmhouse, Traditional, Tropical) and sizes (26” small up to 99” large fans)." />
                <meta name="twitter:title" content="Atlanta Ceiling Fans : Lighting Fixtures" />
                <meta property="og:title" content="Atlanta Ceiling Fans : Lighting Fixtures" />
                <meta property="og:description" content="Shop Atlanta Ceiling Fans in Roswell, Georgia for the best selection of ceiling fans, parts and accessories.  Our showroom has over 150 designer ceiling fans on display many of which are in stock and ready to take home today.  We carry a large selection of indoor and outdoor designer ceiling fans in many styles (Modern, Farmhouse, Traditional, Tropical) and sizes (26” small up to 99” large fans)." />
                <meta property="og:image" content={`${process.env.REACT_APP_API_HOST}/logos/logo_header.svg`} />
            </Helmet>
            <Header />
            <NavBar />
            <LightingFixturesDescription />
            <FeaturedLightingFixtureProductsContainer {...this.props}/>
            <LightingFixturesBrandsContainer />
            <Footer />
        </div>
    }
}

let ConnectedLightingFixturesProductsScreen = connect(state=> {
    return {
        productCategoriesArray: state.productCategoriesArray,
        lightingFixtureCategoriesArray: state.lightingFixtureCategoriesArray,
        productsScreenPreviousYOffset: state.productsScreenPreviousYOffset,
        returningProductsScreenYOffset: state.returningProductsScreenYOffset,
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl
    }
})(LightingFixturesProductsScreen);

export default ConnectedLightingFixturesProductsScreen;