import React from 'react';
import SizesList from './sizes-list';

let SizesContainer = (props) =>
    <div className="sizes-container">
            <h4 className="sizes-header">Sizes Available</h4>
            <SizesList {...props} />
    </div>


export default SizesContainer;