import React from 'react';

let SizesList = (props) =>
    <ul className="sizes-list">
        {props.currentCeilingFanViewed.sizes.map((size, index)=>
            <li className="sizes-list-list-item" key={index}>
                {`${size}"`}
            </li>)}
    </ul>


export default SizesList;