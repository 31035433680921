import React from 'react';
import { NavLink } from 'react-router-dom';

let FeaturedLightingFixtureProductsCard = (props) =>
    <NavLink 
            onClick={(event) => {
                props.dispatch({type: 'UPDATE_PRODUCTS_SCREEN_PREVIOUS_Y_OFFSET', productsScreenPreviousYOffset: (window.pageYOffset)});
            }} 
            className={`featured-products-card-navlink`} 
            to={`/lighting-fixtures/${props.category.name}`}
    >
        <div className={`featured-products-card featured-products-card-${props.category.name}`}>
            <img className={`featured-products-card-image`} src={props.category.imageUrl} alt={props.category.lightingFixtureCategory}/>
            <p className={`featured-products-card-category`}>{props.category.lightingFixtureCategory}</p>
        </div>
    </NavLink>


export default FeaturedLightingFixtureProductsCard;