import React from 'react';
import FanMotorTypeList from './fan-motor-type-list';

let FanMotorTypeContainer = (props) =>
    <div className="fan-motor-type-container">
            <h4 className="fan-motor-type-header">Motor Type</h4>
            <FanMotorTypeList {...props} />
    </div>


export default FanMotorTypeContainer;