import React from 'react';
import PartsAccessoriesCard from './parts-accessories-card';

const partsAccessoriesArray = [
    {
        name: 'light-kits',
        header: 'Light Kits',
        file: 'light_kits',
        type: 'li',
        content: ['Traditional Bowl Lights', 'Low Profile Dome Lights', 'Outdoor Light Kits', '3, 4, and 5 Light Fitters', 'Side Glass']
    },
    {
        name: 'downrods',
        header: 'Downrods',
        file: 'downrods',
        type: 'li',
        content: ['Many colors available in store', 'Lengths: 12" up to 72"', '1/2" i.d. Downrods', '3/4" i.d. Downrods', '1" i.d. Downrods']
    },
    {
        name: 'parts',
        header: 'Parts',
        file: 'parts',
        type: 'li',
        content: ['Pull Chain Switches', 'Light Kit Switches', 'Capacitors', 'Balancing Kits', 'and more...']
    },
    {
        name: 'controls',
        header: 'Controls',
        file: 'controls',
        type: 'li',
        content: ['Handheld Remote Controls', 'In Wall Remove Controls', 'Slider Fan Controls', 'Knob Fan Controls', 'Multiple Fan Controls', 'BOND by Olibra']
    }
];

let PartsAccessoriesCardContainer = () =>
    <div className="parts-accessories-card-container">
        {partsAccessoriesArray.map((card, index)=>
            <PartsAccessoriesCard card={card} key={index} />    
        )}
    </div>


export default PartsAccessoriesCardContainer;