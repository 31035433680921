let postContactFormMessage = async (contactFormMessage, props) => {
    let stringifiedContactFormMessage = JSON.stringify(contactFormMessage);
    let messagePost = await fetch(`${process.env.REACT_APP_API_HOST}/api/contact`, {
        method: 'POST',
        body: stringifiedContactFormMessage,
        headers: {'Content-Type': 'application/json'}
    });
    let messagePostSuccessful = await (messagePost.json());
    if (messagePostSuccessful === 'Success') {
        props.dispatch({type: 'CONTACT_US_FORM_SUCCESSFUL', contactFormNameInput: '', contactFormEmailInput: '', contactFormMessageInput: ''});
        props.history.push('/contact/successful');
    }  else {
        props.history.push('/contact/error')
    }
};

export default postContactFormMessage;