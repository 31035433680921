import React from 'react';

let HomeScreenImageGallery = (props) =>
    <div className="home-screen-image-gallery">
        <div className="home-screen-image-gallery-button-container home-screen-image-gallery-previous-button-container">
            <button 
                onClick={ (event) => {
                    event.preventDefault();
                    let homeScreenImageGalleryCurrentUpdated = (props.homeScreenImageGalleryCurrent + props.homeScreenImageGalleryArray.length - 1) % props.homeScreenImageGalleryArray.length;
                    props.dispatch({type: 'HOME_SCREEN_GALLERY_PREVIOUS_IMAGE', homeScreenImageGalleryCurrent: homeScreenImageGalleryCurrentUpdated})
                }}
                className="home-screen-image-gallery-button home-screen-image-gallery-button-previous"
            >
                {`<`}
            </button>
        </div>
        <div>
            <img className="home-screen-image" src={props.homeScreenImageGalleryArray[props.homeScreenImageGalleryCurrent].url} alt={props.homeScreenImageGalleryArray[props.homeScreenImageGalleryCurrent].manufacturer + ' ' + props.homeScreenImageGalleryArray[props.homeScreenImageGalleryCurrent].name}/>
        </div>
        <div className="home-screen-image-gallery-button-container home-screen-image-gallery-next-button-container">
            <button 
                onClick={ (event) => {
                    event.preventDefault();
                    let homeScreenImageGalleryCurrentUpdated = (props.homeScreenImageGalleryCurrent + props.homeScreenImageGalleryArray.length + 1) % props.homeScreenImageGalleryArray.length;
                    props.dispatch({type: 'HOME_SCREEN_GALLERY_NEXT_IMAGE', homeScreenImageGalleryCurrent: homeScreenImageGalleryCurrentUpdated})
                }}
                className="home-screen-image-gallery-button home-screen-image-gallery-button-next"
            >
                {`>`}
            </button>
        </div>
    </div>


export default HomeScreenImageGallery;