import React from 'react';

let FanLocationContainer = (props) =>
    <div className="fan-location-container">
        <h4 className="fan-location-header">Location</h4>
        <ul className="fan-location-list">
            <li className="fan-locaton-list-item">{props.currentCeilingFanViewed.location}</li>
        </ul>
    </div>


export default FanLocationContainer;