import React from 'react';
import { NavLink } from 'react-router-dom';

let LightingFixtureCategoryCard = (props) =>
    <div className={`category-fan-card category-fan-card-${props.lightingFixture.name}`}>
        <NavLink 
            onClick={(event) => {
                props.dispatch({type: 'UPDATE_CATEGORY_SCREEN_PREVIOUS_Y_OFFSET', categoryScreenPreviousYOffset: (window.pageYOffset)});
                props.dispatch({type: 'SET_CURRENT_LIGHTING_FIXTURE_VIEWED', currentLightingFixtureViewed: props.lightingFixture})
            }} 
            className="category-fan-card-nav-link" 
            to={`/lighting-fixtures/${props.currentLightingFixtureCategory.name}/${props.lightingFixture.productUrl}`}
        >
            {/* <img className="category-fan-card-image" src={`http://d3dvd4avs283wr.cloudfront.net/` + props.fan.imageUrl} alt={props.fan.manufacturer + ' ' + props.fan.title}/> */}
            <img className="category-fan-card-image" src={props.lightingFixture.imageUrl} alt={props.lightingFixture.manufacturer + ' ' + props.lightingFixture.title}/>
            <p className="category-fan-card-name">{props.lightingFixture.title}</p>
        </NavLink>
    </div>


export default LightingFixtureCategoryCard;