import React from 'react';

let LightingFixtureCategoryDescription = (props) =>
    <div className="category-description">
        <h1 className="category-description-header" >{props.currentLightingFixtureCategory.lightingFixtureCategory}</h1>
        <p className="category-description-paragraph">
            {props.currentLightingFixtureCategory.categoryDescription}
        </p>
    </div>


export default LightingFixtureCategoryDescription;