import React from 'react';
import Header from './header';
import NavBar from './nav-bar';
import Footer from './footer';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import 'autotrack';

class BlogScreen extends React.Component {
    componentDidMount() {
        this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname});
        // window.ga('create', 'UA-132930353-1', 'auto');
        // window.ga('require', 'urlChangeTracker');
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
    }
    render() {
        return <div>
            <Helmet>
                <title>Atlanta Ceiling Fans : Blog</title>
                <meta name="description" content="Visit our blog page which offers helpful ceiling fan tips and information to make your buying process a breeze." />
                <meta name="twitter:title" content="Atlanta Ceiling Fans : Blog" />
                <meta property="og:title" content="Atlanta Ceiling Fans : Blog" />
                <meta property="og:description" content="Visit our blog page which offers helpful ceiling fan tips and information to make your buying process a breeze." />
                <meta property="og:image" content={`${process.env.REACT_APP_API_HOST}/logos/logo_header.svg`} />
            </Helmet>
            <Header />
            <NavBar />
            Coming Soon!
            <Footer />
        </div>
    }
}


let ConnectedBlogScreen = connect(state=> {
    return {
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl
    }
})(BlogScreen);

export default ConnectedBlogScreen;