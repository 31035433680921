import React from 'react';
import Header from './header';
import NavBar from './nav-bar';
import CookiesPolicyContainer from './cookies-policy-container';
import Footer from './footer';

class CookiesPolicyScreen extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
    }
    render() {
        return <div>
            <Header />
            <NavBar />
            <CookiesPolicyContainer />
            <Footer />
        </div>
    }
}


export default CookiesPolicyScreen;