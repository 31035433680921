import React from 'react';
import PoliciesTermsCard from './policies-terms-card';

let Footer = () =>
    <div className="footer">
        <div className="footer-logo-address-container">
            <img className="footer-logo" src={`${process.env.REACT_APP_API_HOST}/logos/logo_footer.svg`} alt="Atlanta Ceiling Fans Logo"/>
            <div className="footer-address">
            <p className="footer-address-street">1160 Alpharetta Street, Suite A</p>
            <p className="footer-address-city">Roswell, GA 30075</p>
            <p className="footer-phone">Phone: 770-998-6812</p>
        </div>
        </div>
        <div className="footer-store-hours">
            <h3 className="footer-store-hours-header">STORE HOURS</h3>
            <p className="footer-store-hours-days">Monday - Friday</p>
            <p className="footer-store-hours-times">9:00 AM - 5:00 PM</p>
            <p className="footer-store-hours-days">Saturday</p>
            <p className="footer-store-hours-times">10:00 AM - 5:00 PM</p>
            <p className="footer-store-hours-days">Sunday</p>
            <p className="footer-store-hours-times footer-store-hours-closed">Closed</p>
        </div>
        <div className="footer-connect-with-us">
            <h3 className="footer-connect-with-us-header">Connect With Us</h3>
            <div className="footer-connect-with-us-anchor-container">
                <a className="footer-connect-anchor-facebook footer-connect-with-us-anchor" href="https://www.facebook.com/AtlantaCeilingFans/" target="_blank" rel="noreferrer noopener">
                    <img className="footer-connect-logo-facebook footer-connect-with-us-logo" src={`${process.env.REACT_APP_API_HOST}/logos/facebook.svg`} alt="Facebook Logo" />
                </a>
                <a className="footer-connect-anchor-instagram footer-connect-with-us-anchor" href="https://www.instagram.com/atlantaceilingfans/" target="_blank" rel="noreferrer noopener">
                    <img className="footer-connect-logo-instagram footer-connect-with-us-logo" src={`${process.env.REACT_APP_API_HOST}/logos/instagram.svg`} alt="Instagram Logo" />
                </a>
                <a className="footer-connect-anchor-pinterest footer-connect-with-us-anchor" href="https://www.pinterest.com/atlantaceilingfans/" target="_blank" rel="noreferrer noopener">
                    <img className="footer-connect-logo-pinterest footer-connect-with-us-logo" src={`${process.env.REACT_APP_API_HOST}/logos/pinterest.svg`} alt="Pinterest Logo" />
                </a>
            </div>
            <PoliciesTermsCard />
        </div>
    </div>


export default Footer;