import React from 'react';

let LocationRow = () =>
    <div className="location-row">
        <h3 className="location-row-header">
            Atlanta Ceiling Fans
        </h3>
        <p className="location-row-street-address">
            1160 Alpharetta Street, Suite A, Roswell, Georgia 30075
        </p>
        <p className="location-row-directions">
            We are located off of the GA400 Exit 7B Roswell/Holcomb Bridge Rd. <br />
            Our showroom is one mile south from the intersection of Holcomb Bridge Rd and Highway 9.
        </p>
    </div>


export default LocationRow;