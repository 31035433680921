import React from 'react';

let LightingFixtureLocationRatingList = (props) =>
    <ul className="fan-location-rating-list">
            <li className="fan-location-rating-list-item">
                {props.currentLightingFixtureViewed.locationRating}
            </li>
    </ul>


export default LightingFixtureLocationRatingList;