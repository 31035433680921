import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import CeilingFansByBrandFansContainer from './ceiling-fans-by-brand-fans-container';


let CeilingFansByBrandContainer = (props) =>
    <div className="featured-ceiling-fans-by-brand-container">
        <h2 className="featured-ceiling-fans-by-brand-container-header">
            Featured Products For {props.currentManufacturerDescription.name}
        </h2>
        <CeilingFansByBrandFansContainer {...props} />
        <NavLink {...props}
            onClick={(event) => {
                // console.log(props)
                props.dispatch({type: 'UPDATE_RETURNING_PRODUCTS_SCREEN_Y_OFFSET', returningProductsScreenYOffset: props.productsScreenPreviousYOffset});
                props.dispatch({type: 'RESET_PRODUCTS_SCREEN_PREVIOUS_Y_OFFSET', productsScreenPreviousYOffset: 0});
                props.dispatch({type: 'SET_CATEGORY_FOUND_STATUS', productCategoryFoundStatus: ''})
                props.dispatch({type: 'SET_CEILING_FANS_ARRAY', ceilingFansArray: []})
                props.dispatch({type: 'SET_CURRENT_PRODUCT_CATEGORY', currentProductCategory: {
                    name: '',
                    productCategory: '',
                    categoryDescription: '',
                    imageUrl: ''
                }})
                
            }}
            className="category-screen-back-to-products-screen-nav-link" 
            to={`/ceiling-fans`} 
        >
            {`Back to Ceiling Fan Categories`}
        </NavLink>
    </div>


let ConnectedCeilingFansByBrandContainer = connect(state=> {
    return {
        returningProductsScreenYOffset: state.returningProductsScreenYOffset,
        productsScreenPreviousYOffset: state.productsScreenPreviousYOffset,
        currentProductCategory: state.currentProductCategory
        // previousScreenUrl: state.previousScreenUrl,
        // currentScreenUrl: state.currentScreenUrl
    }
})(CeilingFansByBrandContainer);

export default ConnectedCeilingFansByBrandContainer;