import React from 'react';
import { NavLink } from 'react-router-dom';

let NavBar = () =>
    <div className="nav-bar-links-container">
        <NavLink className="home-nav-link nav-bar-nav-link" activeClassName="selected" to="/" exact={true}>Home</NavLink>
        <NavLink className="products-nav-link nav-bar-nav-link" activeClassName="selected" to="/ceiling-fans" >Fans</NavLink>
        <NavLink className="lighting-fixtures-products-nav-link nav-bar-nav-link" activeClassName="selected" to="/lighting-fixtures">Lighting</NavLink>
        <NavLink className="parts-service-nav-link nav-bar-nav-link" activeClassName="selected" to="/parts" exact={true}>Parts & Accessories</NavLink>
        {/* <NavLink className="blog-nav-link nav-bar-nav-link" activeClassName="selected" to="/blog" exact={true}>Blog</NavLink> */}
        <NavLink className="contact-us-nav-link nav-bar-nav-link" activeClassName="selected" to="/contact" >Contact Us</NavLink>
    </div>


export default NavBar;