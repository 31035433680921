import React from 'react';
import OurBrandsCard from './our-brands-card';
import AboutUsCard from './about-us-card';

let HomeScreenList = (props) =>
    <div className="home-screen-list">
        <OurBrandsCard {...props}/>
        <AboutUsCard />
    </div>


export default HomeScreenList;