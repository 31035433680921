import React from 'react';
import phoneLogo from './phone-logo.svg';

let Header = () =>
    <div className="header">
        <div className="header-logo-container">
            <img className="header-logo" src={`${process.env.REACT_APP_API_HOST}/logos/logo_header.svg`} alt="Atlanta Ceiling Fans Logo"/>
        </div>
        <div className="header-formerly-address-container">
            <div className="header-formerly-container">
                <p className="header-formerly-paragraph">
                    Same Location <br className="header-formerly-paragraph-break"/>
                    Since 1985 <br className="header-formerly-paragraph-break"/>
                </p>
            </div>
            <div className="header-address-container">
                <p className="header-address-paragraph">
                    1160 Alpharetta Street, Suite A<br />
                    Roswell, GA 30075
                </p>
            </div>
        </div>
        <div className="header-phone-hours-container header-item">
            <div className="header-phone-container">
                <div className="header-phone-logo-container">
                    <img className="header-phone-logo" src={phoneLogo} alt="Phone Logo" />
                </div>
                <p className="header-phone-number">
                    {`770-998-6812`}
                </p>
            </div>
            <div className="header-hours-container">
                <p className="header-hours-span header-hours-operation-header">Hours of Operations:</p>
                <p className="header-hours-span header-hours-span-days">Monday - Friday</p>
                <p className="header-hours-span header-hours-span-hours">9:00 AM - 5:00 PM</p>
                <p className="header-hours-span header-hours-span-days">Saturday</p>
                <p className="header-hours-span header-hours-span-hours">10:00 AM - 5:00 PM</p>
            </div>
        </div>
    </div>


export default Header;