import React from 'react';

let LightingFixtureDimensionsContainer = (props) =>
    <div className="sizes-container">
            <h4 className="sizes-header">Dimensions</h4>
            {(props.currentLightingFixtureViewed.maxOverallHeight)?
                <ul className="lighting-fixture-dimensions-list">
                    <li className="lighting-fixture-dimensions-list-item">
                        Height: {props.currentLightingFixtureViewed.fixtureHeight}"
                    </li>
                    <li className="lighting-fixture-dimensions-list-item">
                        Length: {props.currentLightingFixtureViewed.fixtureLength}"
                    </li>
                    <li className="lighting-fixture-dimensions-list-item">
                        Width: {props.currentLightingFixtureViewed.fixtureWidth}"
                    </li>
                    <li className="lighting-fixture-dimensions-list-item">
                        Maximum Overall Height: {props.currentLightingFixtureViewed.maxOverallHeight}"
                    </li>
                    {(props.currentLightingFixtureViewed.availableInOtherSizes === true)?
                        <li className="lighting-fixture-dimensions-list-item">
                            Available in additional sizes.
                        </li>
                    :
                    (props.currentLightingFixtureViewed.availableInOtherSizes === false)?
                        <li className="lighting-fixture-dimensions-list-item">
                            Only available in this size.
                        </li>
                    :
                        <li className="lighting-fixture-dimensions-list-item">
                            Call for details on additional sizes.
                        </li>
                    }    
                </ul>
                :
                <ul className="lighting-fixture-dimensions-list">
                    <li className="lighting-fixture-dimensions-list-item">
                        Height: {props.currentLightingFixtureViewed.fixtureHeight}"
                    </li>
                    <li className="lighting-fixture-dimensions-list-item">
                        Length: {props.currentLightingFixtureViewed.fixtureLength}"
                    </li>
                    <li className="lighting-fixture-dimensions-list-item">
                        Width: {props.currentLightingFixtureViewed.fixtureWidth}"
                    </li>
                    {(props.currentLightingFixtureViewed.availableInOtherSizes === true)?
                        <li className="lighting-fixture-dimensions-list-item">
                            Available in additional sizes.
                        </li>
                    :
                    (props.currentLightingFixtureViewed.availableInOtherSizes === false)?
                        <li className="lighting-fixture-dimensions-list-item">
                            Only available in this size.
                        </li>
                    :
                        <li className="lighting-fixture-dimensions-list-item">
                            Call for details on additional sizes.
                        </li>
                    }    
                </ul>
            }
    </div>


export default LightingFixtureDimensionsContainer;