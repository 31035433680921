import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import HomeScreen from './home-screen';
import CeilingFansProductsScreen from './ceiling-fans-products-screen';
import LightingFixturesProductsScreen from './lighting-fixtures-products-screen';
import CategoryScreen from './category-screen';
import LightingFixtureCategoryScreen from './lighting-fixture-category-screen';
import LightingFixtureDetailsScreen from './lighting-fixture-details-screen';
import CeilingFansByBrandScreen from './ceiling-fans-by-brand-screen';
import PartsAccessoriesScreen from './parts-accessories-screen';
import BlogScreen from './blog-screen';
import ContactUsScreen from './contact-us-screen';
import ContactFormSuccessfulScreen from './contact-form-successful-screen';
import ContactFormErrorScreen from './contact-form-error-screen';
import FanDetailsScreen from './fan-details-screen';
import FanDetailsByBrandScreen from './fan-details-by-brand-screen';
import PageNotFoundScreen from './page-not-found-screen';
import PrivacyPolicyScreen from './privacy-policy-screen';
import TermsConditionsScreen from './terms-conditions-screen';
import CookiesPolicyScreen from './cookies-policy-screen';


let Router = () =>
    <BrowserRouter>
        <div>
            <Switch>
                <Route exact path="/" component={HomeScreen} />
                <Route exact path="/ceiling-fans" component={CeilingFansProductsScreen} />
                <Route exact path="/ceiling-fans/by-brand/:brand" component={CeilingFansByBrandScreen} />
                <Route exact path="/ceiling-fans/by-brand/:brand/:id" component={FanDetailsByBrandScreen} />
                <Route exact path="/ceiling-fans/:id" component={CategoryScreen} />
                <Route exact path="/ceiling-fans/:category/:id" component={FanDetailsScreen} />
                <Route exact path="/lighting-fixtures" component={LightingFixturesProductsScreen} />
                <Route exact path="/lighting-fixtures/:id" component={LightingFixtureCategoryScreen} />
                <Route exact path="/lighting-fixtures/:category/:id" component={LightingFixtureDetailsScreen} />
                <Route exact path="/parts" component={PartsAccessoriesScreen} />
                <Route exact path="/blog" component={BlogScreen} />
                <Route exact path="/contact" component={ContactUsScreen} />
                <Route exact path="/contact/successful" component={ContactFormSuccessfulScreen} />
                <Route exact path="/contact/error" component={ContactFormErrorScreen} />
                <Route exact path="/privacy-policy" component={PrivacyPolicyScreen} />
                <Route exact path="/terms-and-conditions" component={TermsConditionsScreen} />
                <Route exact path="/cookies-policy" component={CookiesPolicyScreen} />
                <Route component={PageNotFoundScreen} />
            </Switch>
        </div>
    </BrowserRouter>


export default Router;