let reducer = (oldState, action) => {
    if (action.type === 'SET_CONTACT_FORM_NAME_INPUT') {
        return {
            ...oldState,
            contactFormNameInput: action.contactFormNameInput
        }
    } else if (action.type === 'SET_CONTACT_FORM_EMAIL_INPUT') {
        return {
            ...oldState,
            contactFormEmailInput: action.contactFormEmailInput
        }
    } else if (action.type === 'SET_CONTACT_FORM_MESSAGE_INPUT') {
        return {
            ...oldState,
            contactFormMessageInput: action.contactFormMessageInput
        }
    } else if (action.type === 'CONTACT_US_FORM_SUCCESSFUL') {
        return {
            ...oldState,
            contactFormNameInput: action.contactFormNameInput,
            contactFormEmailInput: action.contactFormEmailInput,
            contactFormMessageInput: action.contactFormMessageInput,
        }
    } else if (action.type === 'HOME_SCREEN_GALLERY_PREVIOUS_IMAGE') {
        return {
            ...oldState,
            homeScreenImageGalleryCurrent: action.homeScreenImageGalleryCurrent
        }
    } else if (action.type === 'HOME_SCREEN_GALLERY_NEXT_IMAGE') {
        return {
            ...oldState,
            homeScreenImageGalleryCurrent: action.homeScreenImageGalleryCurrent
        }
    } else if (action.type === 'UPDATE_PRODUCTS_SCREEN_PREVIOUS_Y_OFFSET') {
        return {
            ...oldState,
            productsScreenPreviousYOffset: action.productsScreenPreviousYOffset
        }
    } else if (action.type === 'UPDATE_RETURNING_PRODUCTS_SCREEN_Y_OFFSET') {
        return {
            ...oldState,
            returningProductsScreenYOffset: action.returningProductsScreenYOffset
        }
    } else if (action.type === 'RESET_PRODUCTS_SCREEN_PREVIOUS_Y_OFFSET') {
        return {
            ...oldState,
            productsScreenPreviousYOffset: action.productsScreenPreviousYOffset
        }
    } else if (action.type === 'RESET_RETURNING_PRODUCTS_SCREEN_Y_OFFSET') {
        return {
            ...oldState,
            returningProductsScreenYOffset: action.returningProductsScreenYOffset
        }
    } else if (action.type === 'UPDATE_CATEGORY_SCREEN_PREVIOUS_Y_OFFSET') {
        return {
            ...oldState,
            categoryScreenPreviousYOffset: action.categoryScreenPreviousYOffset
        }
    } else if (action.type === 'UPDATE_RETURNING_CATEGORY_SCREEN_Y_OFFSET') {
        return {
            ...oldState,
            returningCategoryScreenYOffset: action.returningCategoryScreenYOffset
        }
    } else if (action.type === 'RESET_CATEGORY_SCREEN_PREVIOUS_Y_OFFSET') {
        return {
            ...oldState,
            categoryScreenPreviousYOffset: action.categoryScreenPreviousYOffset
        }
    } else if (action.type === 'RESET_RETURNING_CATEGORY_SCREEN_Y_OFFSET') {
        return {
            ...oldState,
            returningCategoryScreenYOffset: action.returningCategoryScreenYOffset
        }
    } else if (action.type === 'SET_SCREEN_URLS') {
        return {
            ...oldState,
            previousScreenUrl: action.previousScreenUrl,
            currentScreenUrl: action.currentScreenUrl
        }
    } else if (action.type === 'SET_CEILING_FANS_ARRAY') {
        return {
            ...oldState,
            ceilingFansArray: action.ceilingFansArray
        }
    } else if (action.type === 'SET_CURRENT_PRODUCT_CATEGORY') {
        return {
            ...oldState,
            currentProductCategory:{
                name: action.currentProductCategory.name,
                productCategory: action.currentProductCategory.productCategory,
                categoryDescription: action.currentProductCategory.categoryDescription,
                imageUrl: action.currentProductCategory.imageUrl
            }
        }
    } else if (action.type === 'SET_CURRENT_CEILING_FAN_VIEWED') {
        return {
            ...oldState,
            currentCeilingFanViewed: {
                name: action.currentCeilingFanViewed.name,
                title: action.currentCeilingFanViewed.title,
                manufacturer: action.currentCeilingFanViewed.manufacturer,
                manufacturerNameReference: action.currentCeilingFanViewed.manufacturerNameReference,
                sizes: action.currentCeilingFanViewed.sizes,
                productDescription: action.currentCeilingFanViewed.productDescription,
                motorType: action.currentCeilingFanViewed.motorType,
                handheldRemoteControlAndInWallRemoteControlIncluded: action.currentCeilingFanViewed.handheldRemoteControlAndInWallRemoteControlIncluded,
                handheldRemoteControlIncluded: action.currentCeilingFanViewed.handheldRemoteControlIncluded,
                inWallRemoteControlIncluded: action.currentCeilingFanViewed.inWallRemoteControlIncluded,
                hardwiredWallControlIncluded: action.currentCeilingFanViewed.hardwiredWallControlIncluded,
                handheldRemoteControlAvailable: action.currentCeilingFanViewed.handheldRemoteControlAvailable,
                inWallRemoteControlAvailable: action.currentCeilingFanViewed.inWallRemoteControlAvailable,
                hardwiredWallControlAvailable: action.currentCeilingFanViewed.hardwiredWallControlAvailable,
                smartFan: action.currentCeilingFanViewed.smartFan,
                smartFanAdaptable: action.currentCeilingFanViewed.smartFanAdaptable,
                smartFanDetails: action.currentCeilingFanViewed.smartFanDetails,
                // wifiFunctionalityIncluded: action.currentCeilingFanViewed.wifiFunctionalityIncluded,
                // bluetoothFunctionalityIncluded: action.currentCeilingFanViewed.bluetoothFunctionalityIncluded,
                // wifiAdaptable: action.currentCeilingFanViewed.wifiAdaptable,
                // wifiAdaptableDetails: action.currentCeilingFanViewed.wifiAdaptableDetails,
                locationRating: action.currentCeilingFanViewed.locationRating,
                airflowRating: action.currentCeilingFanViewed.airflowRating,
                lightIncluded: action.currentCeilingFanViewed.lightIncluded,
                lightKitAdaptable: action.currentCeilingFanViewed.lightKitAdaptable,
                universalLightKitAdaptable: action.currentCeilingFanViewed.universalLightKitAdaptable,
                lightKitRequired: action.currentCeilingFanViewed.lightKitRequired,
                lightKitInformation: action.currentCeilingFanViewed.lightKitInformation,
                lumensRating: action.currentCeilingFanViewed.lumensRating,
                mixAndMatch: action.currentCeilingFanViewed.mixAndMatch,
                colors: action.currentCeilingFanViewed.colors,
                shown: action.currentCeilingFanViewed.shown,
                productUrl: action.currentCeilingFanViewed.productUrl,
                imageUrl: action.currentCeilingFanViewed.imageUrl,
                productCategories: action.currentCeilingFanViewed.productCategories,
                styles: action.currentCeilingFanViewed.styles
            }
        }
    } else if (action.type === 'SET_CATEGORY_FOUND_STATUS') {
        return {
            ...oldState,
            productCategoryFoundStatus: action.productCategoryFoundStatus
        }
    } else if (action.type === 'SET_CURRENT_CEILING_FAN_FOUND_STATUS') {
        return {
            ...oldState,
            currentCeilingFanFoundStatus: action.currentCeilingFanFoundStatus
        }
    } else if (action.type === 'SET_PRODUCT_CATEGORIES_ARRAY') {
        return {
            ...oldState,
            productCategoriesArray: action.productCategoriesArray
        }
    } else if (action.type === 'SET_LIGHTING_FIXTURE_CATEGORIES_ARRAY') {
        return {
            ...oldState,
            lightingFixtureCategoriesArray: action.lightingFixtureCategoriesArray
        }
    } else if (action.type === 'SET_LIGHTING_FIXTURE_CATEGORY_FOUND_STATUS') {
        return {
            ...oldState,
            lightingFixtureCategoryFoundStatus: action.lightingFixtureCategoryFoundStatus
        }
    } else if (action.type === 'SET_LIGHTING_FIXTURES_ARRAY') {
        return {
            ...oldState,
            lightingFixturesArray: action.lightingFixturesArray
        }
    } else if (action.type === 'SET_CURRENT_LIGHTING_FIXTURE_CATEGORY') {
        return {
            ...oldState,
            currentLightingFixtureCategory: {
                name: action.currentLightingFixtureCategory.name,
                lightingFixtureCategory: action.currentLightingFixtureCategory.lightingFixtureCategory,
                categoryDescription: action.currentLightingFixtureCategory.categoryDescription,
                imageUrl: action.currentLightingFixtureCategory.imageUrl
            }
        }
    } else if (action.type === 'SET_CURRENT_LIGHTING_FIXTURE_VIEWED') {
        return {
            ...oldState,
            currentLightingFixtureViewed: {
                name: action.currentLightingFixtureViewed.name,
                title: action.currentLightingFixtureViewed.title,
                manufacturer: action.currentLightingFixtureViewed.manufacturer,
                manufacturerNameReference: action.currentLightingFixtureViewed.manufacturerNameReference,
                productDescription: action.currentLightingFixtureViewed.productDescription,
                fixtureHeight: action.currentLightingFixtureViewed.fixtureHeight,
                fixtureLength: action.currentLightingFixtureViewed.fixtureLength,
                fixtureWidth: action.currentLightingFixtureViewed.fixtureWidth,
                maxOverallHeight: action.currentLightingFixtureViewed.maxOverallHeight,
                availableInOtherSizes: action.currentLightingFixtureViewed.availableInOtherSizes,
                locationRating: action.currentLightingFixtureViewed.locationRating,
                colors: action.currentLightingFixtureViewed.colors,
                shown: action.currentLightingFixtureViewed.shown,
                numberOfBulbs: action.currentLightingFixtureViewed.numberOfBulbs,
                bulbType: action.currentLightingFixtureViewed.bulbType,
                bulbWattage: action.currentLightingFixtureViewed.bulbWattage,
                lumensRating: action.currentLightingFixtureViewed.lumensRating,
                productUrl: action.currentLightingFixtureViewed.productUrl,
                partNumber: action.currentLightingFixtureViewed.partNumber,
                imageUrl: action.currentLightingFixtureViewed.imageUrl,
                productCategories: action.currentLightingFixtureViewed.productCategories,
                styles: action.currentLightingFixtureViewed.styles
                
            }
        }
    } else if (action.type === 'SET_CURRENT_LIGHTING_FIXTURE_FOUND_STATUS') {
        return {
            ...oldState,
            currentLightingFixtureFoundStatus: action.currentLightingFixtureFoundStatus
        }
    } else if (action.type === 'SET_CURRENT_MANUFACTURER_DESCRIPTION') {
        return {
            ...oldState,
            currentManufacturerDescription: {
                key: action.currentManufacturerDescription.id,
                name: action.currentManufacturerDescription.name,
                nameReference: action.currentManufacturerDescription.nameReference,
                manufacturerDescription: action.currentManufacturerDescription.manufacturerDescription,
                imageUrl: action.currentManufacturerDescription.imageUrl,
                websiteUrl: action.currentManufacturerDescription.websiteUrl
            }
        }
    } else {
        return {
            ...oldState
        }
    }
};

export default reducer;