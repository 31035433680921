import React from 'react';
import FanControlTypeList from './fan-control-type-list';

let FanControlTypeContainer = (props) =>
    <div className="fan-control-type-container">
            <h4 className="fan-control-type-header">Control Type</h4>
            <FanControlTypeList {...props} />
    </div>


export default FanControlTypeContainer;