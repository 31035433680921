import React from 'react';
import CategoryFanCard from './category-fan-card';

let CategoryFansContainer = (props) =>
    <div className="category-fans-container">
        {/* {props.category.fans.map((fan, index)=>
            <CategoryFanCard fan={fan} key={index} {...props}/>)} */}
        {props.ceilingFansArray.map((fan, index)=>
            <CategoryFanCard fan={fan} key={index} {...props}/>)}
    </div>


export default CategoryFansContainer;