import React from 'react';
import FeaturedCeilingFansCard from './featured-ceiling-fans-card';

let FeaturedCeilingFansContainer = (props) =>
    (props.productCategoriesArray < 1)?
        <p className="loading-container-paragraph">
            Thank You For Your Patience While Your Results Load
        </p>
    :
        <div className="featured-products-container">
            <h2 className="featured-products-container-header">
                Featured Ceiling Fans
            </h2>
            <p className="featured-products-container-paragraph">
                Click on a category below to see some of our best selling fans for that category. We offer many more options in store.
            </p>
            <div className="featured-products-categories-container">
                {props.productCategoriesArray.map((category, index) =>
                    <FeaturedCeilingFansCard category={category} key={index} {...props} />    
                )}
            </div>
        </div>


export default FeaturedCeilingFansContainer;