import React from 'react';

let LightingFixtureDecriptionContainer = (props) =>
    <div className="fan-description-container">
        <p>
            {props.currentLightingFixtureViewed.productDescription}
        </p>
    </div>


export default LightingFixtureDecriptionContainer;