import React from 'react';
import FanAirflowRatingList from './fan-airflow-rating-list';

let FanAirflowRatingContainer = (props) =>
    <div className="fan-airflow-rating-container">
            <h4 className="fan-airflow-rating-header">Airflow Rating</h4>
            <FanAirflowRatingList {...props} />
    </div>


export default FanAirflowRatingContainer;