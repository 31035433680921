import React from 'react';
import Header from './header';
import NavBar from './nav-bar';
import Footer from './footer';
import PartsAccessoriesDescription from './parts-accessories-description';
import PartsAccessoriesCardContainer from './parts-accessories-card-container';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import 'autotrack';
import LightingBanner from './lighting-banner';


class PartsAccessoriesScreen extends React.Component {
    componentDidMount() {
        this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname})
        // window.ga('create', 'UA-132930353-1', 'auto');
        // window.ga('require', 'urlChangeTracker');
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
    }
    render() {
        return <div>
            <Helmet>
                <title>Atlanta Ceiling Fans : Parts & Accessories</title>
                <meta name="description" content="In store we carry a wide selection of ceiling fan parts and accessories such as ceiling fan downrods (diameters from 1/2 to 1” and lengths from 6” to 6ft), capacitors, switches and more.  Give us a call at 770-998-6812 to see if we have the parts you need." />
                <meta name="twitter:title" content="Atlanta Ceiling Fans : Parts & Accessories" />
                <meta property="og:title" content="Atlanta Ceiling Fans : Parts & Accessories" />
                <meta property="og:description" content="In store we carry a wide selection of ceiling fan parts and accessories such as ceiling fan downrods (diameters from 1/2 to 1” and lengths from 6” to 6ft), capacitors, switches and more.  Give us a call at 770-998-6812 to see if we have the parts you need." />
                <meta property="og:image" content={`${process.env.REACT_APP_API_HOST}/logos/logo_header.svg`} />
            </Helmet>
            <Header />
            <NavBar />
            <LightingBanner />
            <PartsAccessoriesDescription />
            <PartsAccessoriesCardContainer />
            <Footer />
        </div>
    }
}

let ConnectedPartsAccessoriesScreen = connect(state=> {
    return {
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl
    }
})(PartsAccessoriesScreen);

export default ConnectedPartsAccessoriesScreen;