import React from 'react';

let CeilingFansDescription = () =>
    <div className="ceiling-fans-description">
        <h1 className="ceiling-fans-description-header">
            Ceiling Fans
        </h1>
        <p className="ceiling-fans-description-paragraph">
            In store you will find a FANtastic selection of brand name <span className="ceiling-fans-description-emphasis">ceiling fans, parts, and accessories.</span> We offer a wide array of styles and sizes in stock. We can even custom design some models, allowing you to choose the motor finish, blade style, lighting, and accessories. We carry the newest innovations in the industry such as energy saving DC motors and Smart Fans that will work from your smartphones, tablets, and more. There are over 150 ceiling fans on display in our showroom and we are always adding more. One of our specialties is <span className="ceiling-fans-description-emphasis">Outdoor rated fans</span> that are made for both Wet and Damp locations. We stock outdoor ceiling fans all year round for customers with porches, patios, and underdeck systems.
        </p>
    </div>


export default CeilingFansDescription;