import React from 'react';
import PartNumberList from './part-number-list';

let PartNumberContainer = (props) =>
    <div className="part-number-container">
            <h4 className="part-number-header">Part Number</h4>
            <PartNumberList {...props} />
    </div>


export default PartNumberContainer;