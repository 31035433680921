import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './header';
import NavBar from './nav-bar';
import Footer from './footer';
import { connect } from 'react-redux';
import 'autotrack';


class ContactFormSuccessfulScreen extends React.Component {
    componentDidMount() {
        this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname});
        // window.ga('create', 'UA-132930353-1', 'auto');
        // window.ga('require', 'urlChangeTracker');
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
    }
    render() {
        return <div>
            <Header />
            <NavBar />
            <div className="message-sent-successfully-container">
                <p className="message-sent-successfully-paragraph">
                    Thank you for reaching out to us, your message was successfully sent. You will receive a response from one of our associates shortly.
                </p>
                <NavLink className='message-sent-successfully-contact-screen-return-navlink' to='/contact' exact={true}>Return to Previous Screen</NavLink>
            </div>
            <Footer />
        </div>
    }
};


let ConnectedContactFormSuccessfulScreen = connect(state=> {
    return {
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl
    }
})(ContactFormSuccessfulScreen);

export default ConnectedContactFormSuccessfulScreen;