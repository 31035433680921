import React from 'react';

let FanLightDetailsListItem = (props) =>
    <React.Fragment>
        {(props.currentCeilingFanViewed.lightIncluded === true && props.currentCeilingFanViewed.lightKitRequired === true)?
            <React.Fragment>
                <li className="fan-light-details-list-item">
                    {props.currentCeilingFanViewed.lightKitInformation}
                </li>
                <li className="fan-light-details-list-item">
                    {props.currentCeilingFanViewed.lumensRating} Lumens
                </li>
                <li className="fan-light-details-list-item">
                    Light must be installed on the fan
                </li>
            </React.Fragment>
        :
        (props.currentCeilingFanViewed.lightIncluded === true && props.currentCeilingFanViewed.lightKitRequired === false)? 
            <React.Fragment>
                <li className="fan-light-details-list-item">
                    {props.currentCeilingFanViewed.lightKitInformation}
                </li>
                <li className="fan-light-details-list-item">
                    {props.currentCeilingFanViewed.lumensRating} Lumens
                </li>
                <li className="fan-light-details-list-item">
                    Cap can be installed on the fan in place of the light
                </li>
            </React.Fragment>
        :
        (props.currentCeilingFanViewed.lightIncluded === false && props.currentCeilingFanViewed.lightKitAdaptable === true && props.currentCeilingFanViewed.universalLightKitAdaptable === false)?
            <React.Fragment>
                <li className="fan-light-details-list-item">
                    {props.currentCeilingFanViewed.lightKitInformation}
                </li>
                <li className="fan-light-details-list-item">
                    {props.currentCeilingFanViewed.lumensRating} Lumens
                </li>
                <li className="fan-light-details-list-item">
                    Light Kit sold separately
                </li>
            </React.Fragment>
        :
        (props.currentCeilingFanViewed.lightIncluded === false && props.currentCeilingFanViewed.lightKitAdaptable === true && props.currentCeilingFanViewed.universalLightKitAdaptable === true)?
            <React.Fragment>
                <li className="fan-light-details-list-item">
                    Universal Light Kit can be installed on this fan (Light Kit Sold Separately)
                </li>
                <li className="fan-light-details-list-item">
                    Lumens Rating will depend on the Universal Light Kit and Light Bulbs installed on the fan (Sold Separately)
                </li>
            </React.Fragment>
        :
        (props.currentCeilingFanViewed.lightIncluded === false && props.currentCeilingFanViewed.lightKitAdaptable === false)?
            <li className="fan-light-details-list-item">
                No Light Kit Option Available For This Fan
            </li>
        :
            <li className="fan-light-details-list-item">
                Call or email us for details
            </li>
        }
    </React.Fragment>
    // <React.Fragment>
    //     {(props.currentCeilingFanViewed.lightIncluded === true)?
    //             (props.currentCeilingFanViewed.lightKitRequired === true)?
    //                     <li>
    //                         Light must be installed on the fan.
    //                     </li>
    //             :
    //             (props.currentCeilingFanViewed.lightKitRequired === false) 
    //                     <React.Fragment>
    //                         <li>
    //                             {props.currentCeilingFanViewed.lightKitInformation}
    //                         </li>
    //                         <li>
    //                             Cap can be installed on the fan in place of the light.
    //                         </li>
    //                     </React.Fragment>
    //         :
    //         (props.currentCeilingFanViewed.lightIncluded === false)
    //             (props.currentCeilingFanViewed.lightKitAdaptable === true)?
    //                     <React.Fragment>
    //                         <li>
    //                             {props.currentCeilingFanViewed.lightKitInformation}
    //                         </li>
    //                         <li>
    //                             Light Kit sold separately.
    //                         </li>
    //                     </React.Fragment>
    //             :
    //             (props.currentCeilingFanViewed.lightKitAdaptable === false)
    //                     <li>
    //                         No Light Kit Option Available For This Fan.
    //                     </li>
    //             :
    //                 console.log('hello');
    //                     <li>
    //                         Call or email us for details.
    //                     </li>
    //     }
    // </React.Fragment>

export default FanLightDetailsListItem;