import React from 'react';
import Header from './header';
import NavBar from './nav-bar';
import Footer from './footer';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import LoadingContainer from './loading-container';
import 'autotrack';


class LightingFixtureLoadingScreen extends React.Component {
    componentDidMount() {
        window.scrollTo(0, this.props.returningCategoryScreenYOffset);
        this.props.dispatch({type: 'RESET_RETURNING_CATEGORY_SCREEN_Y_OFFSET', returningCategoryScreenYOffset: 0});
        this.props.dispatch({type: 'SET_SCREEN_URLS', previousScreenUrl: this.props.currentScreenUrl, currentScreenUrl: this.props.location.pathname});
        // window.ga('create', 'UA-132930353-1', 'auto');
        // window.ga('require', 'urlChangeTracker');
        // window.ga('set', 'page', `${this.props.location.pathname}`)
        // window.ga('send', 'pageview');
    }
    render() {    
        return <div className="loading-screen">
                    <Helmet>
                        <title>
                            Atlanta Ceiling Fans : {(this.props.currentLightingFixtureCategory.lightingFixtureCategory)}
                        </title>
                        <meta name="description" content="Visit our blog page which offers helpful ceiling fan tips and information to make your buying process a breeze." />
                        <meta name="twitter:title" content="Atlanta Ceiling Fans" />
                        <meta property="og:title" content="Atlanta Ceiling Fans" />
                        <meta property="og:description" content="Visit our blog page which offers helpful ceiling fan tips and information to make your buying process a breeze." />
                        <meta property="og:image" content={`${process.env.REACT_APP_API_HOST}/logos/logo_header.svg`} />
                    </Helmet>
                    <Header />
                    <NavBar />
                    <LoadingContainer {...this.props}/>
                    <Footer />
        </div>
    }
}

let ConnectedLightingFixtureLoadingScreen = connect(state=> {
    return {
        lightingFixtureCategoriesArray: state.lightingFixtureCategoriesArray,
        lightingFixturesArray: state.lightingFixturesArray,
        currentLightingFixtureCategory: state.currentLightingFixtureCategory,
        lightingFixtureCategoryFoundStatus: state.lightingFixtureCategoryFoundStatus,
        categoriesFansObject: state.categoriesFansObject,
        productsScreenPreviousYOffset: state.productsScreenPreviousYOffset,
        returningProductsScreenYOffset: state.returningProductsScreenYOffset,
        categoryScreenPreviousYOffset: state.categoryScreenPreviousYOffset,
        returningCategoryScreenYOffset: state.returningCategoryScreenYOffset,
        previousScreenUrl: state.previousScreenUrl,
        currentScreenUrl: state.currentScreenUrl    
    }
})(LightingFixtureLoadingScreen);

export default ConnectedLightingFixtureLoadingScreen;