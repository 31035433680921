import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import FanDetailsRow from './fan-details-row';

let FanDetailsByBrandContainer = (props) => 
    <div className="fan-details-container">
        <h2 className="fan-details-header">
            {`${props.currentCeilingFanViewed.manufacturer} ${props.currentCeilingFanViewed.title}`}
        </h2>
        <FanDetailsRow {...props} />
        <NavLink 
            onClick={(event) => {
                props.dispatch({type: 'UPDATE_RETURNING_CATEGORY_SCREEN_Y_OFFSET', returningCategoryScreenYOffset: props.categoryScreenPreviousYOffset});
                props.dispatch({type: 'RESET_CATEGORY_SCREEN_PREVIOUS_Y_OFFSET', categoryScreenPreviousYOffset: 0});
            }}
            className="category-screen-back-to-products-screen-nav-link" 
            to={`/ceiling-fans/by-brand/${props.match.params.brand}`} 
        >
            {`Back to ${props.currentManufacturerDescription.name}`}
        </NavLink>
    </div>


let ConnectedFanDetailsByBrandContainer = connect(state=> {
    return {
        returningCategoryScreenYOffset: state.returningCategoryScreenYOffset,
        categoryScreenPreviousYOffset: state.categoryScreenPreviousYOffset
    }
})(FanDetailsByBrandContainer);

export default ConnectedFanDetailsByBrandContainer;