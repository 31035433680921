import React from 'react';


let LoadingContainer = (props) => 
    <div className="loading-container">
        <p className="loading-container-paragraph">
            Thank You For Your Patience While Your Results Load
        </p>
    </div>


export default LoadingContainer;