import React from 'react';

let PartsAccessoriesCard = (props) => {
    return <div className={`parts-accessories-card ${props.card.name}-card`}>
        <div className='parts-accessories-card-image-container'>
            <img className='parts-accessories-card-image' src={`${process.env.REACT_APP_API_HOST}/images/${props.card.file}.jpg`} alt={props.card.header} />
        </div>
        <div className={`parts-accessories-card-information ${props.card.name}-card`}>
            <h1 className={`parts-accessories-card-header ${props.card.header}-header`}>{props.card.header}</h1>
            {(props.card.type === 'li') ?
                <ul className={`parts-accessories-card-list ${props.card.name}-list`}>
                    {props.card.content.map((item, index)=>
                        <li className={`parts-accessories-card-list-item ${props.card.name}-list-item`} key={index}>
                            {item}
                        </li>
                        )}
                </ul>    
                :
                <p className={`parts-accessories-card-paragraph ${props.card.name}-paragraph`}>
                    {props.card.content}
                </p>
            }
        </div>
    </div>
}

export default PartsAccessoriesCard;