import React from 'react';
import FeaturedLightingFixtureProductsCard from './featured-lighting-fixture-products-card';

let FeaturedLightingFixtureProductsContainer = (props) =>
    (props.lightingFixtureCategoriesArray < 1)?
        <p className="loading-container-paragraph">
            Thank You For Your Patience While Your Results Load
        </p>
    :
        <div className="featured-lighting-fixture-products-container">
            <h2 className="featured-lighting-fixture-products-container-header">
                Featured Lighting Products
            </h2>
            <p className="featured-products-container-paragraph">
                Click on a category below to see some of our best selling lighting fixtures for that category. We offer many more options in store.
            </p>
            <div className="featured-products-categories-container">
                {props.lightingFixtureCategoriesArray.map((category, index) =>
                    <FeaturedLightingFixtureProductsCard category={category} key={index} {...props} />    
                )}
            </div>
        </div>


export default FeaturedLightingFixtureProductsContainer;